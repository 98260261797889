import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { isNonNullChain } from "typescript";
import client from 'client';
import { LoadingButton } from '@mui/lab';
// import { sendTicketEmailData } from '../../main/chat/store/NewInspectionSlice';
import store from 'app/store';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  LinearProgress,
} from '@mui/material';

const styles = {
  width: '100%',
};

function ModelInvoiceEmailSend(props) {
  const prop_data = props?.prop_data;
  const state = store.getState();
  const comp_acct = prop_data
    ? prop_data?.compacctnum
    : state?.loadz_auth?.AuthReducer?.user?.company.compacctnum;

  const dispatch = useDispatch();
  // console.log('autofill emails: ', props.toTable);
  //moved to useEffect
  /*  let emailBind =
    props &&
    props.toTable &&
    props.toTable.length > 0 &&
    props.toTable[0].customer &&
    props.toTable[0].customer.customerEmail
      ? props.toTable[0].customer.customerEmail
      : ''; */

  /* let invoiceContacts = []
  if(props?.toTable[0]?.customer?.invoiceContacts && props?.toTable[0]?.customer?.invoiceContacts.length>0)
  {
  props?.toTable[0]?.customer?.invoiceContacts.map((contact)=>{
      if(contact.email)
      {
        invoiceContacts.push(contact.email)
      }
    })
  } */

  const [emails, setEmails] = useState([]);
  const [controlEmails, setControlEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [autofillDialogOpen, setAutoFillDialogOpen] = useState(false);

  const sendEmailData = async () => {
    setLoading(true);
    if (emails && emails.length > 0) {
      if (props && props.toTable && props.toTable.length > 0) {
        props.toTable[0].customer.invoiceNo = props.invoiceNo;
        props.toTable[0].customer.compName = props.compName;
        props.toTable[0].customer.newTicketAry = props.tkData;
        // props.toTable[0].customer.customerTerm = Number(props.terms).toFixed(0)

        if (props && props.terms && props.terms.length > 0) {
          let parsedTerms = [];
          props.terms.forEach((element) => {
            parsedTerms.push(Number(element).toFixed(0));
          });
          props.toTable[0].customer.customerTerm = parsedTerms;
        } else {
          props.toTable[0].customer.customerTerm = Number(props.terms).toFixed(0);
        }

        if (props.invoiceDate) {
          props.toTable[0].customer.newDateInvoice = moment(props.invoiceDate).format('MM/DD/YYYY');
        }
        props.toTable[0].customer.pages = props.pageDivid;
        // if(props && props.infoData && props.infoData.dueDate){
        if (props.toTable[0] && props.toTable[0].customer.jobs.length > 0) {
          props.toTable[0].customer.discounts = props && props.discounts && props.discounts;
          props.toTable[0].tickets = props && props.tickets && props.tickets;
          props.toTable[0].compacctNumSub = props && props.compacctNumSub && props.compacctNumSub;
          props.toTable[0].info = props && props.info && props.info;
          props.toTable[0].comp_acct = comp_acct;
          props.toTable[0].customer.jobs.forEach((job, index) => {
            if (props && props.infoData && props.infoData.dueDate) {
              let dueDateSet = moment(
                props.infoData && props.infoData.dueDate ? props.infoData.dueDate : ''
              ).format('MM/DD/YYYY');
              props.toTable[0].customer.jobs[index].newDueDate = dueDateSet;
              props.toTable[0].customer.discounts = props && props.discounts && props.discounts;
              props.toTable[0].customer.balance =
                props && props.info && props.info.balance && props.info.balance;
              let dateCreated = moment(
                props.infoData && props.infoData.dateCreated ? props.infoData.dateCreated : ''
              ).format('MM/DD/YYYY');
              props.toTable[0].customer.jobs[index].dateCreated = dateCreated;
            }
            job.batches.forEach((batch) => {
              let batchDate = moment(
                batch && batch.batchDateTimeReal ? batch.batchDateTimeReal : ''
              ).format('MM/DD/YYYY');

              batch.newBatchDateTime = batchDate;
            });
          });
        }
        // }

        let newData = props.toTable[0];
        let data = {
          data: newData ? newData : null,
          invoiceEmail: emails,
        };

        //dispatch(sendInvoiceEmailData(data));
        const response = await client.post(`/inspection/sendInvoiceEmail`, data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    props.emailhandleOpen();
  };
  useEffect(() => {
    setLoadingSpinner(true);
  }, []);

  useEffect(() => {
    let timeout1, timeout2, timeout3, timeout4;
    let progressInterval;

    // Mensaje después de 10 segundos
    timeout1 = setTimeout(() => {
      setMessage("We're paving the way for your request. Fresh asphalt coming soon!");
    }, 20000);

    // Mensaje después de 20 segundos
    timeout2 = setTimeout(() => {
      setMessage("Like a well-planned road project, this needs precision. Almost done!");
    }, 30000);

    // Mensaje después de 30 segundos
    timeout3 = setTimeout(() => {
      setMessage(
        'The digital asphalt is cooling... Almost ready to roll!'
      );
    }, 40000);

    // Mensaje después de 40 segundos
    timeout4 = setTimeout(() => {
      setMessage("We're making sure there's no construction delay on your request");
    }, 50000);

   

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
        clearTimeout(timeout4);
        clearInterval(progressInterval);
      };
    }
  , [loadingSpinner]);

  useEffect(async () => {
    const result = await client.post('/props/autofill/get', {
      customer_id: props.toTable[0].customer.id,
      compacctNumSub: props.compacctNumSub,
    });
    let invoice_contacts = result.data; //array of strings
    console.log('invoice contacts: ', invoice_contacts.contacts);

    setControlEmails(invoice_contacts.contacts ? invoice_contacts.contacts : []);

    let emailBind =
      props &&
      props.toTable &&
      props.toTable.length > 0 &&
      props.toTable[0].customer &&
      props.toTable[0].customer.customerEmail
        ? props.toTable[0].customer.customerEmail
        : '';

    if (invoice_contacts.contacts) {
      setEmails([...invoice_contacts.contacts]);
    } else {
      setEmails([emailBind]);
    }
  }, []);

  const saveAutofillEmails = async () => {
    try {
      await client.post('/props/autofill/update', {
        customer_id: props.toTable[0].customer.id,
        contacts: emails,
        compacctNumSub: props.compacctNumSub,
      });
      setAutoFillDialogOpen(false);
      return;
    } catch (error) {
      console.log('Error saving autofill emails: ', error);
    }
  };

  return (
    <div style={styles}>
      <ReactMultiEmail
        placeholder='Input your Email Address'
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        getLabel={(email, index, removeEmail = index) => {
          return (
            <Chip
              className='mr-4'
              data-tag-handle
              label={email}
              onDelete={() => removeEmail(index)}
            />
          );
        }}
      />

      <br />
      {loadingSpinner && (
        <div className='w-full px-4 mb-4'>
          <LinearProgress
            variant='determinate'
            value={progress}
            className='mb-2'
            sx={{
              height: 8,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
              },
            }}
          />
          <div className='flex justify-between items-center'>
            <Typography variant='body2' color='textSecondary'>
              {message}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {`${Math.round(progress)}%`}
            </Typography>
          </div>
        </div>
      )}

      <div className='text-center'>
        <LoadingButton
          loading={loading}
          variant='contained'
          onClick={(e) => {
            e.stopPropagation();
            // Check if an email was added
            let flag = false;
            const addedEmails = emails.filter((email) => !controlEmails.includes(email));
            if (addedEmails.length > 0) {
              console.log('Added emails: ', addedEmails);
              flag = true;
            }

            // Check if an email was removed
            const removedEmails = controlEmails.filter((email) => !emails.includes(email));
            if (removedEmails.length > 0) {
              console.log('Removed emails: ', removedEmails);
              flag = true;
            }

            if (flag == true) {
              //open dialog box prompting user to save changes to autofill
              setAutoFillDialogOpen(true);
            } else {
              sendEmailData();
              //console.log('Sending the email')
            }
          }}
          className='mx-auto mt-8 cursor-pointer'
        >
          Send Email
        </LoadingButton>
      </div>

      <br />

      <Dialog
        open={autofillDialogOpen}
        onClose={() => {
          setAutoFillDialogOpen(false);
        }}
      >
        <DialogContent className='flex flex-row w-full items-center justify-center align-center'>
          <Typography fontWeight={'bold'}>
            {`You have changed your preset emails for this ${
              props.compacctNumSub ? 'Owner Operator' : 'customer'
            } do you want to save the changes?`}
          </Typography>
        </DialogContent>
        <DialogActions className='flex flex-row w-full items-center justify-center align-center p-16'>
          <div className='flex flex-row w-1/2 items-center justify-between align-center'>
            <Button
              size='small'
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                saveAutofillEmails();
                sendEmailData();
              }}
            >
              <Typography>Save Changes</Typography>
            </Button>
            <Button
              size='small'
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                setAutoFillDialogOpen(false);
                sendEmailData();
              }}
            >
              <Typography>Do Not Save Changes</Typography>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModelInvoiceEmailSend;
