import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import store from 'app/store';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment-timezone';

import Grid from '@mui/material/Grid';
import { Alert, Button, Dialog, Tooltip } from '@mui/material';
import Counters from './Counters';
import ChartRange from './ChartRange';
import ChartProgress from './charts-components/ChartProgress';
import ChartDelayed from './charts-components/ChartDelayed';
import ChartCompleted from './charts-components/ChartCompleted';

import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';

import { constants, URLWOCLOUDFLARE } from '../../../constants';

import Snackbar from '@mui/material/Snackbar';
import { injectReducer } from 'app/store';
import JobTable from './JobTable';
import { connect } from 'react-redux';
import AuthReducer from 'app/stores/loadz/redux/Auth';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { RevenueReport } from './components/RevenueReport';
import { hasPermission } from 'app/main/loadz/utils';
import ButtonLog from '../ButtonLog';
import QuickDriverEarnings from './components/QuickDriverEarnings';
import client from 'client';
import FuseLoading from '@fuse/core/FuseLoading';
import TicketPopup from '../Ticket_jobs/TicketPopup';

let SOCKET = null;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const action = (
  <Fragment>
    <Button color='secondary' size='small'>
      UNDO
    </Button>
    <IconButton size='small' aria-label='close' color='inherit'>
      <CloseIcon fontSize='small' />
    </IconButton>
  </Fragment>
);

function Dashboard({ delay, company, user }) {
  const compname = company?.compname;
  const comp_email = company?.comp_email;
  const comp_acct = company?.compacctnum;

  //const { compname, comp_email, compacctnum: comp_acct } = company
  const [showLoading, setShowLoading] = useState(!delay);
  const jobTypes = { progress: 0, completed: 1, delay: 2 };
  const [expandedProgress, setExpandedProgress] = useState(false);
  const [expandedDelayed, setExpandedDelayed] = useState(false);
  const [expandedCompleted, setExpandedCompleted] = useState(false);

  const [counters, setCounters] = useState({});

  const [progress, setProgress] = useState(0);
  const [delayed, setDelayed] = useState(0);
  const [completed, setCompleted] = useState(0);

  const [qtyProgress, setQtyProgress] = useState(0);
  const [qtyDelayed, setQtyDelayed] = useState(0);
  const [qtyCompleted, setQtyCompleted] = useState(0);

  const [progressTickets, setProgressTickets] = useState(0);
  const [delayedTickets, setDelayedTickets] = useState(0);
  const [completedTickets, setCompletedTickets] = useState(0);

  const [isProgress, setIsProgress] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [response, setResponse] = useState('');

  const dispatch = useDispatch();

  const refreshProgress = useRef(null);
  const refreshDelayed = useRef(null);
  const refreshCompleted = useRef(null);
  const refreshGraph = useRef(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const handleExpandClickProgress = () => {
    setExpandedProgress(!expandedProgress);
  };

  const handleExpandClickDelayed = () => {
    setExpandedDelayed(!expandedDelayed);
  };

  const handleExpandClickCompleted = () => {
    setExpandedCompleted(!expandedCompleted);
  };

  const goTo = (id) => {
    var elmntToView = document.getElementById(id);
    elmntToView.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(async () => {
    try {
      SOCKET = io(constants.URLSOCKET, {
        withCredentials: true,
        forceNew: true,
      });
      SOCKET.on('connect', () => {
        SOCKET.on('fromServer', (data) => {
          let json = JSON.parse(data);
          if (json.company == comp_acct) {
            let message = `Ticket ${
              json.completed
                ? `Completed: ${json.tk_no}`
                : json.inProgress
                ? `In Progress: ${json.tk_no}`
                : `Delayed: ${json.tk_no}`
            }`;
            setMessage(message);
            let color = json.completed ? 'success' : json.inProgress ? 'warning' : 'error';
            setSeverity(color);

            if (data) {
              setOpenSnack(true);
              setTimeout(() => {
                setOpenSnack(false);
              }, 20000);

              refreshCompleted.current();
              refreshProgress.current();
              refreshDelayed.current();

              if (json.completed) {
                refreshGraph.current();
              }
            }
          }
        });
      });

      //en caos de error cerrar todas las conexiones y dejar un intervalo de conexion
    } catch (error) {
      if (_socket) {
        SOCKET.disconnect();
      }
    }
    setTimeout(() => {
      setShowLoading(false);
    }, 1500);
  }, []);

  useEffect(
    () => () => {
      SOCKET?.disconnect();
    },
    []
  );

  const [revenueReport, setRevenueReport] = useState(false);
  const [revenueReportDate, setRevenueReportDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
  const [revenueReportLoading, setRevenueReportLoading] = useState(false);
  const [makeCsv, setMakeCsv] = useState(false);
  const [emails, setEmails] = useState(
    store.getState().loadz_auth?.AuthReducer?.user?.user_email
      ? store.getState().loadz_auth.AuthReducer.user.user_email
      : []
  );

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const generateRvenueReport = async () => {
    try {
      ButtonLog({ type: 'dashboard', button_name: 'dashboard_report' });
      if (!revenueReportDate && !dateEnd) {
        return _showMessage('Please select a date', 'error');
      }

      setRevenueReportLoading(true);

      const result = await fetch(`${URLWOCLOUDFLARE}/jobs/daily-revenue-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: `${localStorage.token}`,
          timezone: moment.tz.guess(true),
        },
        body: JSON.stringify({
          startdate: revenueReportDate,
          enddate: dateEnd,
          compacctnum: company.compacctnum,
          csv: makeCsv,
          emails,
          compName: company.compname,
        }),
      });

      if (result.status === 500 || result.status === 404) {
        setRevenueReportLoading(false);
        setRevenueReport(false);
        setRevenueReportDate(moment().format('YYYY-MM-DD'));
        setDateEnd(moment().add(1, 'days').format('YYYY-MM-DD'));
        setMakeCsv(false);
        return _showMessage('Error generating report', 'error');
      }

      let start_date = revenueReportDate;
      let end_date = dateEnd;

      if (start_date && end_date) {
        if (moment(start_date).isAfter(end_date)) {
          const temp = end_date;
          end_date = start_date;
          start_date = temp;
        }

        if (moment(end_date).isBefore(start_date)) {
          const temp = end_date;
          end_date = start_date;
          start_date = temp;
        }
      } else {
        if (start_date && !dateEnd) {
          end_date = start_date;
        } else if (!start_date && dateEnd) {
          start_date = end_date;
        }
      }

      let isJson = false;

      console.log(result.headers.get('content-type'));

      if (result.headers.get('content-type').includes('application/json')) {
        try {
          const json = await result.json();

          if (result.status === 200) {
            setRevenueReportLoading(false);
            setRevenueReport(false);
            setRevenueReportDate(moment().format('YYYY-MM-DD'));
            setDateEnd(moment().add(1, 'days').format('YYYY-MM-DD'));
            setMakeCsv(false);
            _showMessage(json.message, 'success');
            isJson = true;
            return;
          }

          setRevenueReportLoading(false);
          setMessage(json.message);
          isJson = true;
        } catch (error) {
          console.log(error);
        }
      }

      if (result.headers.get('content-type') != 'application/json' && isJson === false) {
        let filename = `daily_revenue_report_${company.compname}_${
          start_date ? moment(start_date).format('MM-DD-YYYY') + '_' : ''
        }${start_date ? 'to_' : ''}${end_date ? moment(end_date).format('MM-DD-YYYY') : ''}`;
        if (makeCsv) {
          filename = `${filename}.csv`;
        } else {
          filename = `${filename}.xlsx`;
        }

        try {
          const blob = await result.blob();
          const file = window.URL.createObjectURL(blob);
          saveAs(file, filename);

          setRevenueReportLoading(false);
          setRevenueReport(false);
          setRevenueReportDate(moment().format('YYYY-MM-DD'));
          setDateEnd(moment().add(1, 'days').format('YYYY-MM-DD'));
          setMakeCsv(false);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setRevenueReportLoading(false);
      setRevenueReport(false);
      setRevenueReportDate(moment().format('YYYY-MM-DD'));
      setDateEnd(moment().add(1, 'days').format('YYYY-MM-DD'));
      setMakeCsv(false);
    }
  };



  const [driverEarnings,setDriverEarnings] = useState([]);
  const [driverTimesheets,setDriverTimesheets] = useState([]);
  const [selectedDriver,setSelectedDriver] = useState(null);
  const [openQuickDriverEarnings, setOpenQuickDriverEarnings] = useState(false);

  const [earningsLoading,setEarningsLoading] = useState(false);

  const getDriverEarnings = async (driver,compacctnum) => {
    try {
      setEarningsLoading(true);
      const earnings_result = await client.get(`/salary/single-current/${driver}/${compacctnum}`);
      console.log('getDriverEarnings earnings_result => ',earnings_result.data.driver_breakdowns)
      

      const timesheets_result = await client.post('/salary/get-single-timesheet',{
        comp_acct: compacctnum,
        driver_id: driver,
        startDate: earnings_result.data.driver_breakdowns[0].batches[0].start,
        endDate: earnings_result.data.driver_breakdowns[0].batches[0].end,
      });
      console.log('getDriverEarnings timesheets_result => ',[timesheets_result.data])

      setDriverEarnings(earnings_result.data.driver_breakdowns);
      setDriverTimesheets([timesheets_result.data]);
      setSelectedDriver(driver);
      setOpenQuickDriverEarnings(true);
      setEarningsLoading(false);

    } catch (error) {
      console.log('getDriverEarnings ERROR => ',error)
      setEarningsLoading(false);
      _showMessage('Error getting driver earnings','error');
    }
  }

  const handleCloseQuickDriverEarnings = () => {
    setOpenQuickDriverEarnings(false);
    setDriverEarnings([]);
    setDriverTimesheets([]);
    setSelectedDriver
  }

  const [openTicketPopup,setOpenTicketPopup] = useState(false);
  const [ticketData,setTicketData] = useState(null);
  const [ticketAuditData,setTicketAuditData] = useState([]);
  const [auditLoading,setAuditLoading] = useState(false);

  const getTicketAudit = async (ticket) => {
    try {
      
    
      ////console.log('Ticket info: ',ticket)
      setAuditLoading(true);

      const result = await client.post(`ticket/audit`,{tk_id:ticket.tk_id,compacctnum:ticket.c_id});
      ////console.log('data: ',result)

      if(result.data.length)
      {
        setTicketAuditData(result.data);
      }
      else
      {
        setTicketAuditData([]);
      }
      setAuditLoading(false);
    } catch (error) {
      setAuditLoading(false);
      //console.log(error)
      setTicketAuditData([]);
      _showMessage('Error','error');
    }
  }

  function getTimeDuration(timeData) {
    if (
      timeData &&
      timeData.tk_wait_start &&
      timeData.tk_wait_start.length > 0 &&
      timeData.tk_wait_end &&
      timeData.tk_wait_end.length > 0
    ) {
      try {
        //console.log('WAIT TIMES > ', timeData);
        let total_hours = 0;
        let total_minutes = 0;
        if (timeData.tk_wait_start.length == timeData.tk_wait_end.length) {
          for (let i = 0; i < timeData.tk_wait_start.length; i++) {
            var start_time =
              moment.tz(timeData.tk_wait_start[i], moment.tz.guess(true)).format('HH:mm') ==
              'Invalid date'
                ? moment
                    .tz(
                      `${moment().format('YYYY-MM-DD')} ` + timeData.tk_wait_start[i],
                      moment.tz.guess(true)
                    )
                    .format('HH:mm:ss')
                : moment.tz(timeData.tk_wait_start[i], moment.tz.guess(true)).format('HH:mm:ss');
            var end_time =
              moment.tz(timeData.tk_wait_end[i], moment.tz.guess(true)).format('HH:mm') ==
              'Invalid date'
                ? moment
                    .tz(
                      `${moment().format('YYYY-MM-DD')} ` + timeData.tk_wait_end[i],
                      moment.tz.guess(true)
                    )
                    .format('HH:mm:ss')
                : moment.tz(timeData.tk_wait_end[i], moment.tz.guess(true)).format('HH:mm:ss');
            var time_diff = diff(start_time, end_time);
            time_diff = time_diff.split(':');
            //console.log(time_diff);

            const hours = +time_diff[0];
            const minutes = +time_diff[1];
            //console.log(hours);
            //console.log(minutes);
            if (hours) total_hours = total_hours + hours;
            if (minutes) total_minutes = total_minutes + minutes;
          }
        }
        while (total_minutes >= 60) {
          total_minutes = total_minutes - 60;
          total_hours = total_hours + 1;
        }
        let tkTime = total_hours + ' Hours ' + total_minutes + ' Minutes';
        return tkTime;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  function getTkStartEndTime(startEndTime) {
    if (startEndTime && startEndTime.tk_start && startEndTime.tk_end) {
      try {
        let total_hours = 0;
        let total_minutes = 0;
        for (let i = 0; i < startEndTime.tk_start.length; i++) {
          //console.log('TIMES ', startEndTime.tk_start[i], startEndTime.tk_end[i]);
          const tkStart =
            moment.tz(startEndTime.tk_start[i], moment.tz.guess(true)).format('HH:mm:ss') ==
            'Invalid date'
              ? moment
                  .tz(
                    `${moment().format('YYYY-MM-DD')} ` + startEndTime.tk_start[i],
                    moment.tz.guess(true)
                  )
                  .format('HH:mm:ss')
              : moment.tz(startEndTime.tk_start[i], moment.tz.guess(true)).format('HH:mm:ss');
          const tkEnd =
            moment.tz(startEndTime.tk_end[i], moment.tz.guess(true)).format('HH:mm:ss') ==
            'Invalid date'
              ? moment
                  .tz(
                    `${moment().format('YYYY-MM-DD')} ` + startEndTime.tk_end[i],
                    moment.tz.guess(true)
                  )
                  .format('HH:mm:ss')
              : moment.tz(startEndTime.tk_end[i], moment.tz.guess(true)).format('HH:mm:ss');
          //console.log('tkStart,tkEnd', tkStart, tkEnd);
          var time_diff = diff(tkStart, tkEnd);
          //console.log('time_diff', time_diff);
          time_diff = time_diff.split(':');

          const hours = +time_diff[0];
          const minutes = +time_diff[1];
          if (hours) total_hours = total_hours + hours;
          if (minutes) total_minutes = total_minutes + minutes;
        }

        while (total_minutes >= 60) {
          total_minutes = total_minutes - 60;
          total_hours = total_hours + 1;
        }
        let tkTime = total_hours + ' Hours ' + total_minutes + ' Minutes';
        return tkTime;
      } catch (err) {
        //console.log(err.message);
        return null;
      }
    } else {
      return null;
    }
  }

  const getTicketData = async(ticketId) => {
    try {
      
    
      const result = await client.post('/jobs/ticket-data',{tk_id: ticketId});
      console.log('getTicketData result => ',result.data);
      

      let key = result.data?.logo;
      if (!key.includes('http')) 
      {
        result.data.logo = `${constants.URLLOCAL}/aws/signed?image=${key}`
      }

      if(result.data?.tk_status != 3) 
      { 
        let duration =  getTimeDuration(result.data);
        let tkDuration =  getTkStartEndTime(result.data);
        if (duration || tkDuration) {
          result.data.duration_time = duration;
          result.data.tk_start_end_duration = tkDuration;
        }
      }
      
      /**
      * result.data.newJobDestination
      **/

     

      setTicketData(result.data);
      setOpenTicketPopup(true);
      getTicketAudit(result.data);
    } catch (error) {
      setTicketData(null);
      setOpenTicketPopup(false);
      _showMessage('Error opening ticket popup','error');
    }
  }

  const handleCloseQuickTicketPopup = () => {
    setOpenTicketPopup(false);
    setTicketData(null);
    setTicketAuditData([]);
  }



  if (showLoading) {
    return (
      <div
        style={{ backgroundColor: '#000000' }}
        className={clsx(
          'flex flex-1 flex-col items-center justify-center p-24',
          !showLoading && 'hidden'
        )}
      >
        {showLoading && (
          <>
            <Typography className='text-13 sm:text-20 mb-16' color='textSecondary'>
              Loading...
            </Typography>
            <LinearProgress className='w-192 sm:w-320 max-w-full rounded-2' color='secondary' />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={clsx('flex flex-1 flex-col p-24 mobile-p-0 dashboard-table')}
        style={{ backgroundColor: '#000000' }}
      >
        {!showLoading && (
          <>
            <Grid container spacing={2}>
              <Snackbar
                sx={{
                  paddingTop: '50px',
                  color: severity,
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnack}
                autoHideDuration={6000}
              >
                <Alert
                  onClick={() => {}}
                  onClose={handleCloseSnack}
                  severity={severity ? severity : 'warning'}
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>

              <Grid item xs={12}>
                <div
                  className='flex w-full justify-between align-center'
                  style={{ padding: '0 20px' }}
                >
                  <Typography component='span' className=' h1 font-Regular text-white' align='left'>
                    {compname}
                  </Typography>
                  {hasPermission('dashboard-revenue-report') && (
                    <Tooltip
                      placement='left'
                      title={<h6 style={{ color: 'white' }}>Daily Revenue Report</h6>}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setRevenueReport(true);
                        }}
                      >
                        <EventNoteIcon style={{ color: 'white', height: '30px', width: '30px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <div className='flex w-full mobile-flex-col flex-col md-screens lg:flex-row'>
                  <div className='w-full lg:w-3/5  mobile-width-full m-d-screenss'>
                    <Card
                      sx={{}}
                      className='rounded-none'
                      style={{
                        backgroundColor: '#000000',
                      }}
                    >
                      <CardHeader title='' subheader='' />
                      <CardContent className='dashboardGraph'>
                        <ChartRange refreshGraph={refreshGraph} compacct={comp_acct} />
                      </CardContent>
                    </Card>
                  </div>
                  <div className='flex w-full lg:w-3/5 mobile-width-full'>
                    <div className='w-full '>
                      <Card
                        className='rounded-none '
                        style={{
                          backgroundColor: '#000000',
                        }}
                      >
                        <CardHeader title='' subheader='' />
                        <CardContent className='dashboardCounters' sx={{}}>
                          <Counters
                            counters={{ progress, delayed, completed }}
                            goTo={goTo}
                            progressTickets={progressTickets}
                            delayedTickets={delayedTickets}
                            completedTickets={completedTickets}
                          />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader
                    title='In Progress'
                    className='text-white'
                    subheader=''
                    id='tableProgress'
                  />

                  <CardContent>
                    <JobTable
                      getTicketData={getTicketData}
                      getDriverEarnings={getDriverEarnings}
                      setJobCount={setProgress}
                      refreshTable={refreshProgress}
                      ticketCount={setProgressTickets}
                      setQuantity={setQtyProgress}
                      compacct={comp_acct}
                      jobType={jobTypes.progress}
                      jobStatus='Progress'
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      className='moress'
                      expand={expandedProgress}
                      onClick={handleExpandClickProgress}
                      aria-expanded={expandedProgress}
                      aria-label='show more'
                    >
                      <Tooltip title={!expandedProgress ? 'Show Chart' : 'Hide Chart'}>
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedProgress} timeout='auto' unmountOnExit>
                    <CardContent>
                      <ChartProgress qtyProgress={qtyProgress} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader
                    title='Completed Today'
                    className='text-white'
                    subheader=''
                    id='tableCompleted'
                  />

                  <CardContent>
                    <JobTable
                      getTicketData={getTicketData}
                      getDriverEarnings={getDriverEarnings}
                      setJobCount={setCompleted}
                      refreshTable={refreshCompleted}
                      ticketCount={setCompletedTickets}
                      setQuantity={setQtyCompleted}
                      compacct={comp_acct}
                      jobType={jobTypes.completed}
                      jobStatus='Completed'
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expandedCompleted}
                      onClick={handleExpandClickCompleted}
                      aria-expanded={expandedCompleted}
                      aria-label='show more'
                    >
                      <Tooltip title={!expandedCompleted ? 'Show Chart' : 'Hide Chart'}>
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedCompleted} timeout='auto' unmountOnExit>
                    <CardContent>
                      <ChartCompleted qtyCompleted={qtyCompleted} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader title='Delayed' className='text-white' id='tableDelayed' />

                  <CardContent>
                    <JobTable
                      getTicketData={getTicketData}
                      getDriverEarnings={getDriverEarnings}
                      isDelayed={response.isDelayed}
                      setJobCount={setDelayed}
                      refreshTable={refreshDelayed}
                      ticketCount={setDelayedTickets}
                      setQuantity={setQtyDelayed}
                      compacct={comp_acct}
                      jobType={jobTypes.delay}
                      jobStatus='Delayed'
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expandedDelayed}
                      onClick={handleExpandClickDelayed}
                      aria-expanded={expandedDelayed}
                      aria-label='show more'
                    >
                      <Tooltip title={!expandedDelayed ? 'Show Chart' : 'Hide Chart'}>
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedDelayed} timeout='auto' unmountOnExit>
                    <CardContent>
                      <ChartDelayed qtyDelayed={qtyDelayed} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            </Grid>

            <RevenueReport
              open={revenueReport}
              setOpen={setRevenueReport}
              date={revenueReportDate}
              setDate={setRevenueReportDate}
              generateReport={generateRvenueReport}
              loading={revenueReportLoading}
              setMakeCsv={setMakeCsv}
              makeCsv={makeCsv}
              dateEnd={dateEnd}
              setDateEnd={setDateEnd}
              setSendEmails={setEmails}
              SendEmails={emails}
              message={message}
            />

            {
              earningsLoading ? 
              <Dialog
                open={earningsLoading}
              >
                <FuseLoading/>
              </Dialog>
              : null
            }

            <QuickDriverEarnings 
              open={openQuickDriverEarnings}
              handleClose={handleCloseQuickDriverEarnings}
              driverEarnings={driverEarnings}
              driverTimesheets={driverTimesheets}
              selectedDriver={selectedDriver}
            />

            <TicketPopup
              getTicketAudit={getTicketAudit}
              open={openTicketPopup}
              handleclose={handleCloseQuickTicketPopup}
              data={ticketData}
              logo={ticketData?.logo}
              newLoc={ticketData?.newJobDestination}
              auditLoading={auditLoading}
              ticketAuditData={ticketAuditData}
            />
            
          </>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

Dashboard.defaultProps = {
  delay: false,
};
injectReducer('loadz_auth', AuthReducer);

const mapStateToProps = (state) => {
  return {
    company: state?.loadz_auth?.AuthReducer?.user?.company,
    user: state?.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps, null)(memo(Dashboard));
