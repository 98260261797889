import React, { useRef, useState } from "react";
import Button from '@mui/material/Button';
import ReactToPrint from "react-to-print";
import EarningsReportToPrint from './EarningsReportToPrint'
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function PrintComponent({ show, earnings,timesheets,batch_access, id, display}) {
  let componentRef = useRef();


  const printTrigger = React.useCallback(() => {
    return  show ?
      (<IconButton style={{fontSize: 14}}>{show}&nbsp;<PrintIcon/></IconButton>) :
      (<IconButton><PrintIcon/></IconButton>)

  }, [])

  const handleOnBeforeGetContent = () => {
    earnings = {}
  }

  if(id) {
    var documentTitle = `${earnings[0].name}_EarningsReport_from-${earnings[0].batches.at(batch_access).start}-to-${earnings[0].batches.at(batch_access).end}`
  } else {
    var documentTitle = `EarningsReport_from-${earnings[0].batches.at(batch_access).start}-to-${earnings[0].batches.at(batch_access).end}`
  }

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={printTrigger}
          documentTitle={documentTitle}
          onBeforeGetContent={handleOnBeforeGetContent}
          content={() => componentRef.current}
        />

        {/* component to be printed */}
        {
          display ? (
            <div>
              <EarningsReportToPrint id={id} earnings={earnings} timesheets={timesheets} batch_access={batch_access} ref={componentRef} />
            </div>
          ) : (
            <div style={{display: 'none'}}>
              <EarningsReportToPrint id={id} earnings={earnings} timesheets={timesheets} batch_access={batch_access} ref={componentRef} />
            </div>
          )
        }
      </div>
    </>
  );
}
