import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { constants } from '../../../../constants'
import { setSelectedContactId } from './InspectionTruckSlice'
import { setSelectedContact } from './InspectionTrailerSlice'
import store from 'app/store'
import client from 'client'
export const getChat = createAsyncThunk(
  'chatAppdd/chat/getChat',
  async ({ id, tra, trailers, trucks, isMobile }, { dispatch, getState }) => {
    const state = store.getState()
    const comp_id = state.loadz_auth.AuthReducer.user.user_id
    const response = await client.post(
      `/new-inspections/inspection-log/${id}`,
      { comp_id }
    )
    const data = await response.data
    dispatch(setSelectedContactId(id))
    return data
  }
)

export const gettrailer = createAsyncThunk(
  'chatAppdd/chat/getChat',
  async ({ id, tra, trailers, trucks, isMobile }, { dispatch, getState }) => {
    const state = store.getState()
    const comp_id = state.loadz_auth.AuthReducer.user.user_id

    const response = await client.post(
      `/new-inspections/inspection-log/${id}`,
      { comp_id }
    )
    const data = await response.data
    dispatch(setSelectedContact(id))
    return data
  }
)

export const sendEmailData = createAsyncThunk(
  'NewInspectionsApp/sendemail/sendEmailData',
  async (emailData) => {
    const response = await client.post(`/inspection/sendEmail`, emailData)
    const data = await response.data
    return data
  }
)

export const sendTicketEmailData = createAsyncThunk(
  'NewInspectionsApp/sendemail/sendTicketEmail',
  async (emailTkData) => {
    const response = await client.post(
      `/inspection/sendTicketEmail`,
      emailTkData
    )
    const data = await response.data
    return data
  }
)

export const sendTicketEmailBOLData = createAsyncThunk(
  'NewInspectionsApp/sendemail/sendTicketEmail',
  async (emailTkData) => {
    const response = await client.post(
      `/inspection/sendTicketEmailBOL`,
      emailTkData
    )
    const data = await response.data
    return data
  }
)

export const sendInvoiceEmailData = createAsyncThunk(
  'NewInspectionsApp/sendemail/sendInvoiceEmail',
  async (invoiceEmail) => {
    const response = await client.post(
      `/inspection/sendInvoiceEmail`,
      invoiceEmail
    )
    const data = await response.data
    return data
  }
)

export const updateInspection = createAsyncThunk(
  'InspectionApp/Inspections/updateInspection',
  async (Inspection, { dispatch, getState }) => {
    const response = await client.put(
      `/inspection/${Inspection.t_inspect_q_id}`,
      Inspection
    )
    const data = await response.data

    dispatch(getChat())

    return data
  }
)

const chatSlice = createSlice({
  name: 'NewInspectionsApp/chat',
  initialState: null,
  reducers: {
    removeChat: (state, action) => action.payload,
  },
  extraReducers: {
    [getChat.fulfilled]: (state, action) => action.payload,
  },
})

export default chatSlice.reducer
