import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { sendTicketEmailData } from '../../main/chat/store/NewInspectionSlice';
import axios from 'axios';
import { constants } from '../../../constants';
import client from 'client';

const styles = {
  width: '100%',
};

function TicketMultiEmail(props) {
  const dispatch = useDispatch();
  // console.log('props email => ', props);
  let emailData = props && props.data && props.data.customer_email ? props.data.customer_email : '';
  const [emails, setEmails] = useState([emailData]);

  const getUrl = async (key) => {
    if (key) {
      if (key.includes('http')) {
        return key;
      }
      const url = `${constants.URLLOCAL}/aws/get`;
      try {
        const result = await axios.post(url, { key });
        //console.log(`url::`, result?.data);
        return result?.data?.data?.fileLocation;
      } catch (error) {
        console.log('error in signature sendEmailTicket error::', error);
      }
    }
    return null;
  };

  const sendEmailData = async () => {
    if (emails && emails.length > 0) {
      console.log(' sendEmailData props.data => ', props.data);
      let newData = JSON.parse(JSON.stringify(props.data));
      if (props && props.data) {
        newData.logo = props.logo.logoKey;
        newData.newJobDestination = props.newLoc;
        if (newData?.tk_receievedby_mediaurl) {
          newData.tk_receievedby_mediaurl = await getUrl(newData.tk_receievedby_mediaurl);
        }

        if (newData?.scale_ticket_image) {
          //get correct URL for scale_ticket_image
          const new_scale_ticket_image = [];
          if (Array.isArray(newData.scale_ticket_image) && newData.scale_ticket_image.length > 0) {
            for (const image of newData.scale_ticket_image) {
              const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
                params: { image: image, url: true },
              });
              new_scale_ticket_image.push(response?.data);
            }
          }

          if (!Array.isArray(newData.scale_ticket_image)) {
            const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
              params: { image: newData.scale_ticket_image, url: true },
            });
            new_scale_ticket_image.push(response?.data);
          }

          newData.scale_ticket_image = new_scale_ticket_image;
        }

        if (newData.bol_type == true) {
          let bol_image = [];

          await Promise.all(
            newData.bol_file.map(async (image) => {
              const new_image = JSON.parse(JSON.stringify(image));

              const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
                params: { image: new_image.key, url: true },
              });
              const imageUrl = response?.data;

              new_image.bol_image = imageUrl;
              bol_image.push(new_image);
            })
          );

          newData.bol_image = bol_image; //is now an array that contains the bol_image for the url
        }

        //console.log('newData.bol_image => ',newData.bol_image);

        if (newData) {
          let data = {
            data: newData,
            ticketEmail: emails,
          };
          console.log('data => ', data);
          dispatch(sendTicketEmailData(data));
          //dispatch(sendTicketEmailBOLData(data));
        }
      }
    }
    props.emailhandleOpen();
  };

  return (
    <div style={styles}>
      <ReactMultiEmail
        placeholder='Input your Email Address'
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        getLabel={(email, index, removeEmail = index) => {
          return (
            <Chip
              className='mr-4'
              data-tag-handle
              label={email}
              onDelete={() => removeEmail(index)}
            />
          );
        }}
      />
      <div className='text-center'>
        <Button variant='contained' onClick={sendEmailData} className='mx-auto mt-8 cursor-pointer'>
          Send Email
        </Button>
      </div>
      <br />
    </div>
  );
}

export default TicketMultiEmail;
