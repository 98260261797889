import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { red, green } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import Grafica from './tabs/Grafica';
import {
  Grid,
  Card,
  CardContent,
  Container,
  Input,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Checkbox,
  Chip,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { changeCurrency, formatter, PERMISSION_MODEL } from '../../../constants';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ModalCreateMaterials from './modalbatches';
import ModalReportTikets from './report_tickets_csv';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@material-ui/core';
import CreateTicketModal from './tabs/CreateTicketModal';
import { connect } from 'react-redux';
import withReducer from 'app/store/withReducer';
import JobTicketAction from 'app/stores/loadz/redux/JobsReport/actions';
import JobTicketReducer from 'app/stores/loadz/redux/JobsReport';
import TicketsTable from './tabs/TicketsTable';
import { hasPermission } from '../loadz/utils';
import ErrorBoundary from '../ErrorBoundary';
import { showMessage } from 'app/store/fuse/messageSlice';
import client from 'client';
import { ReactMultiEmail } from 'react-multi-email';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Hidden from '@mui/material/Hidden';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ButtonLog from '../ButtonLog';

const editMoneyNums = (num) => {
  if (num) {
    var temp = (+num.split('$')[1].replace(/[^0-9\.]/g, '')).toFixed(2);
    if (temp) {
      if (temp.split('.').length == 2) {
        if (temp.split('.')[1].length < 2) {
          temp = temp + '0';
        }
      }
    }
    return temp;
  }
};

const editNums = (num) => {
  if (num) {
    var temp = num.toLocaleString();
    if (temp && temp.split('.').length == 2) {
      if (temp.split('.')[1].length < 2) {
        temp = temp + '0';
      }
    }
  }
  return temp;
};

function ProfilePage({
  searchJobTickets,
  searchJobTicketsDate,
  getAllTicketJob,
  getMetrics,
  metrics,
  getJob,
  jobInfo,
  user,
  permission,
  setJob_id,
  setTicketTableOpen,
  setEditJobBillingOpen,
  setEditJobOpen,
  job_id,
  data,
  clearAllTicketJobs
}) {
  const routeParams = useParams();
  const { id } = routeParams;
  const [selectedTab, setSelectedTab] = useState(1);
  const [JsonGetById, setJsonGetById] = useState(0);
  const [search, setSearch] = useState(routeParams?.ticket ? routeParams?.ticket : '');
  const [jdestination, setjdestination] = useState(0);
  const [jobbilling, setjobbilling] = useState(0);
  const [quarrypit, setquarrypit] = useState(0);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [houlingRevenue, setHoulingRevenue] = useState();
  const [Houlingcost, setHoulingcost] = useState();
  const [meterialRevenue, setMaterialRevenue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setticket] = useState();
  const refreshGraph = useRef(null);
  const history = useHistory();
  const user_permission = useSelector(({ auth }) => auth.user.role);
  const [modalShow, setModalShow] = React.useState(false);

  const [invoice, setInvoice] = useState('');
  const [emails, setEmails] = useState([]);
  const [sendwhen, setSendWhen] = useState(true);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const isPermission = true;

  const dispatch = useDispatch();
  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  useEffect(async () => {
    handleSearchstatus(1);
    
    await getJob({ id: job_id ? job_id : id });
    
  }, [id, job_id]);

  useEffect(() => {
    if (jobInfo) {
      setJsonGetById(jobInfo);
      setjdestination(jobInfo.j_destination);
      setjobbilling(jobInfo.job_billing ? jobInfo.job_billing : []);
      setquarrypit(jobInfo.quarry_pit);
      setticket(jobInfo.ticket);

      let complet = jobInfo ? jobInfo.ticketcompleted : 0;
      let fillmen = jobInfo ? jobInfo.j_fullfillment_total : 0;
      let a = complet * 100;
      const porcentaje = a / fillmen;
      if (porcentaje >= 100) {
        setProgress(100);
      } else {
        setProgress(porcentaje);
      }
    }
  }, [jobInfo]);

  const handleSearchstatus = async (tk_status) => {
    if (id) {
      const compacctnum = data ? data?.compacctnum : user?.company?.compacctnum;
      if (hasPermission(PERMISSION_MODEL.readFinancialValue)) {
        await getMetrics({ id: job_id ? job_id : id, status: tk_status, compacctnum });
      }
    }
  };

  useEffect(() => {
    var ecomm = editNums(metrics?.employee_commissions ? metrics.employee_commissions : 0);
    var subcost = editMoneyNums(
      metrics?.sub_contractor_costs ? metrics.sub_contractor_costs : '$0'
    );
    var opcost = editMoneyNums(metrics?.owner_operator_costs ? metrics.owner_operator_costs : '$0');
    const fi = Number(ecomm) + Number(subcost) + Number(opcost);
    setHoulingcost(formatter.format(fi));
    setHoulingRevenue(
      editNums(+(+editMoneyNums(metrics?.hauling_sales ? metrics.hauling_sales : 0)) - +fi)
    );
    setMaterialRevenue(
      editNums(
        +(+editMoneyNums(metrics?.material_revenue)) - +editMoneyNums(metrics?.material_cost)
      )
    );
  }, [metrics]);

  function handleTabChange(event, value) {
    /* if(value != 3)
    {
      setSelectedTab(value);
      handleSearchstatus(value);
    } */
      setSelectedTab(value);
      handleSearchstatus(value);
      setDate1('')
      setDate2('')
      setSearch('')
  }
  function LinearProgressWithLabel(props) {
    return (
      <Box className="jobTprogress flex justify-center items-center h-full flex-col">
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={`${Math.round(props.value)}`}
            style={{ width: '140px', height: '140px', margin: '0 auto' }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              sx={{ fontWeight: 'bold', fontSize: '30px', color: 'black' }}
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenBatches = () => {
    setIsOpen(true);
  };

  const handleClickOpenReport = () => {
    setOpenReport(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenReport(false);
    setModalShow(false);
  };

  const openTicketForm = () => {
    setModalShow(true);
  };

  function EditJobOpen() {
    setEditJobOpen(true);
    setTicketTableOpen(false);
  }

  function editjobs(id) {
    data ? EditJobOpen() : history.push(`/jobs/by-id/${id}`);
    return;
  }

  async function invoicejob(job_id) {
    try {//
      if (sendwhen == true && emails.length == 0) {
        setLoading(false);
        _showMessage('Please enter email address', 'error');
        return;
      }
      await ButtonLog({type:'invoice',button_name:'invoice_create',info:`Ticket Page Invoice Job send emails ${sendwhen}`})
      const response = await client.post('/invoices/create-job-invoice', {
        Jobs: [job_id],
        compacctnum: data ? data?.compacctnum : user?.company?.compacctnum,
        emails,
        send: sendwhen,
      });
      if (response.data.error) {
        _showMessage(response.data.error, 'error');
      }
      if (response.data.message) {
        _showMessage(response.data.message, 'success');
      }
      setCreateInvoice(false);
      setInvoice('');
      setSendWhen(true);
      setEmails([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      _showMessage('An error occurred', 'error');
      setCreateInvoice(false);
      setInvoice('');
      setSendWhen(true);
      setEmails([]);
      setLoading(false);
    }
    return;
  }

  const onSearchClick = async () => {
    const compacctnum = data ? data?.compacctnum : user?.company?.compacctnum;
    console.log('search', selectedTab);
    await searchJobTickets({ id, searchText: search, accountNumber: compacctnum,selectedTab });
  };

  const onSearchDate = async () => {
    const compacctnum = data ? data?.compacctnum : user?.company?.compacctnum;
    await searchJobTicketsDate({ id, start: date1,end:date2, accountNumber: compacctnum,selectedTab,column: ticketSearch});
  };

  const [ticketSearch, setTicketSearch] = useState('completed');
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  
 


  return (
    <ErrorBoundary>
      <Container
        maxWidth="xl"
        className="main-jobgrid"
      >
        <Grid
          spacing={2}
          style={{
            flexWrap: 'wrap',
            marginTop: '80px',
            backgroundColor: '#fff',
            padding: '10px 20px',
            borderRadius: '15px',
            display: 'flex',
          }}
          className="grid-outter"
        >
          <Grid
            md={4}
            sm={12}
          >
            {data ? (
              <Typography
                className="h3 backjobs"
                role="button"
                onClick={async() => {
                  setTicketTableOpen(false);
                  await clearAllTicketJobs()
                }}
              >
                <Tooltip title="Back to Jobs Overview">
                  <Icon
                    className="text-20"
                    sx={{ fontSize: 100 }}
                    onClick={async() => {
                      await clearAllTicketJobs()
                    }}
                  >
                    {'arrow_back'}
                  </Icon>
                </Tooltip>
                {} Back to Jobs Overview
              </Typography>
            ) : (
              <Typography
                className="h3 backjobs"
                component={Link}
                role="button"
                to="/jobs"
                onClick={async() => {
                  await clearAllTicketJobs()
                }}
              >
                <Tooltip title="Back to Jobs Overview">
                  <Icon
                    className="text-20"
                    sx={{ fontSize: 100 }}
                    onClick={async() => {
                      await clearAllTicketJobs()
                    }}
                  >
                    {'arrow_back'}
                  </Icon>
                </Tooltip>
                {} Back to Jobs Overview
              </Typography>
            )}

            <Grid
              container
              className="job-container"
              style={{ margin: '0', height: '100%' }}
            >
              <Grid
                xs={12}
                className="jobnumber-outter"
                style={{
                  textAlign: 'left',
                  padding: '10px',
                  paddingLeft: '35px',
                  borderRight: '0px solid #F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className="job-number job-number2">
                  <div className="job-hading">
                    <Typography
                      className="text-24 leading-tight font-Regular"
                      color="textSecondary"
                    >
                      Job No:
                    </Typography>
                    <Tooltip
                      followCursor={true}
                      title={`${
                        JsonGetById.og_co ? JsonGetById.og_co + '-' + JsonGetById.og_job : ''
                      }`}
                      placement={'top'}
                    >
                      <Typography
                        className="md:text-64 text-44 font-bold tracking-tighter"
                        style={{ lineHeight: '1' }}
                        color="textSecondary"
                      >
                        {JsonGetById.company_id}-{JsonGetById.job_no}
                      </Typography>
                    </Tooltip>
                  </div>
                  <div className="job-editbtn">
                    {hasPermission('jobs.read') && (
                      <Button
                        className="text-16 job-edit-btn p-0"
                        style={{ minWidth: 'auto' }}
                        onClick={(event) => editjobs(job_id ? job_id : id)}
                        type="submit"
                      >
                        {' '}
                        Edit{' '}
                      </Button>
                    )}
                    {jobbilling.auto_invoice == true && hasPermission('invoice.create') && (
                      <Button
                        className="text-16 job-edit-btn pl-24"
                        style={{ minWidth: 'auto' }}
                        onClick={(e) => {
                          console.log(JsonGetById.job_info_id);
                          //invoicejob(JsonGetById.job_info_id)
                          console.log(jobbilling)
                          setInvoice(JsonGetById.job_info_id);
                        }}
                      >
                        {' '}
                        Invoice Job{' '}
                      </Button>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid
                sm={12}
                md={6}
                className="jobbixes5 jobBoxes"
                style={{
                  borderRight: '5px solid #F5F5F5',
                  textAlign: 'center',
                  padding: '10px',
                  borderTop: '5px solid #F5F5F5',
                }}
              >
                <LinearProgressWithLabel value={progress ? progress : 0} />
              </Grid>
              <Grid
                sm={12}
                md={6}
                className="jobbixes5 jobBoxes"
                style={{ textAlign: 'center', padding: '10px', borderTop: '5px solid #F5F5F5' }}
              >
                <div className="flex justify-center items-center h-full flex-col job-completion">
                  <Typography className="font-bold text-left">Job Completion :</Typography>
                  <Typography className="font-normal text-left">
                    {Number(JsonGetById.ticketcompleted).toFixed(2)}/
                    {JsonGetById.j_fullfillment_total} {jobbilling ? jobbilling.unit_name : '-'}
                  </Typography>
                </div>
              </Grid>
              <Grid
                sm={12}
                md={6}
                className="jobbixes6 jobBoxes"
                style={{
                  borderTop: '5px solid #F5F5F5',
                  padding: '10px',
                  borderRight: '5px solid #F5F5F5',
                }}
              >
                <div className="mt-0">
                  <div className="py-5">
                    <Typography
                      variant="span"
                      className="font-bold text-left"
                    >
                      Destination:{' '}
                    </Typography>
                    <Typography
                      variant="span"
                      className="font-normal text-left"
                    >
                      {jdestination ? jdestination.l_name : '-'}
                    </Typography>
                  </div>
                  <div className="py-5">
                    <Typography
                      variant="span"
                      className="font-bold text-left"
                    >
                      {quarrypit &&  quarrypit?.l_type ?  quarrypit?.l_type?.charAt(0).toUpperCase() + quarrypit?.l_type.slice(1) : '-'}:{' '}
                    </Typography>
                    <Typography
                      variant="span"
                      className="font-normal text-left"
                    >
                      {quarrypit ? quarrypit.l_name : '-'}
                    </Typography>
                  </div>
                  {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
                    <div className="py-5">
                      <Typography
                        variant="span"
                        className="font-bold text-left"
                      >
                        Contractor Unit Rate:{' '}
                      </Typography>
                      <Typography
                        variant="span"
                        className="font-normal text-left"
                      ></Typography>
                    </div>
                  )}
                  <div className="py-5">
                    <Typography
                      variant="span"
                      className="font-bold text-left"
                    >
                      Unit:{' '}
                    </Typography>
                    <Typography
                      variant="span"
                      className="font-normal text-left"
                    >
                      {jobbilling ? jobbilling.unit_name : '-'}
                    </Typography>
                  </div>
                  {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
                    <div className="py-5">
                      <Typography
                        variant="span"
                        className="font-bold text-left"
                      >
                        Unit Sale Rate:{' '}
                      </Typography>
                      <Typography
                        variant="span"
                        className="font-normal text-left"
                      >
                        ${jobbilling ? Number(jobbilling.bill_per_unit).toFixed(2) : '-'}
                      </Typography>
                    </div>
                  )}
                  {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
                    <div className="py-5">
                      <Typography
                        variant="span"
                        className="font-bold text-left"
                      >
                        Unit Cost Rate:{' '}
                      </Typography>
                      <Typography
                        variant="span"
                        className="font-normal text-left"
                      >
                        ${jobbilling ? Number(jobbilling.cost_per_unit).toFixed(2) : '-'}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid
                sm={12}
                md={6}
                className="jobbixes7"
                style={{ borderTop: '5px solid #F5F5F5', padding: '10px' }}
              >
                <div className="mt-0">
                  <div className="py-5">
                    <Typography
                      variant="span"
                      className="font-bold text-left"
                    >
                      Material:{' '}
                    </Typography>
                    <Typography
                      variant="span"
                      className="font-normal text-left"
                    >
                      {jobbilling ? jobbilling.name : '-'}
                    </Typography>
                  </div>
                  {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
                    <div className="py-5">
                      <Typography
                        variant="span"
                        className="font-bold text-left"
                      >
                        Material Sale Rate:{' '}
                      </Typography>
                      <Typography
                        variant="span"
                        className="font-normal text-left"
                      >
                        ${jobbilling ? Number(jobbilling.material_invoice_rate).toFixed(2) : '-'}
                      </Typography>
                    </div>
                  )}

                  {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
                    <div className="py-5">
                      <Typography
                        variant="span"
                        className="font-bold text-left"
                      >
                        Material Cost Rate:{' '}
                      </Typography>
                      <Typography
                        variant="span"
                        className="font-normal text-left"
                      >
                        ${jobbilling ? Number(jobbilling.material_cost_rate).toFixed(2) : '-'}
                      </Typography>
                    </div>
                  )}
                  <div className="py-5">
                    <Typography className="">
                      <b className="font-bold">Commissions: </b>
                      {jobbilling.commissions ? (
                        <CheckBoxIcon sx={{ color: green[600] }}></CheckBoxIcon>
                      ) : (
                        <IndeterminateCheckBoxIcon
                          sx={{ color: red[600] }}
                        ></IndeterminateCheckBoxIcon>
                      )}
                    </Typography>
                  </div>
                  <div className="py-5">
                    <Typography className="">
                      <b className="font-bold">Scale: </b>
                      {JsonGetById.scale ? (
                        <CheckBoxIcon sx={{ color: green[600] }}></CheckBoxIcon>
                      ) : (
                        <IndeterminateCheckBoxIcon
                          sx={{ color: red[600] }}
                        ></IndeterminateCheckBoxIcon>
                      )}
                    </Typography>
                  </div>
                  <div className="py-5">
                    <Typography className="">
                      <b className="font-bold">Material Broker: </b>{' '}
                      {jobbilling.material_broker ? (
                        <CheckBoxIcon sx={{ color: green[600] }}></CheckBoxIcon>
                      ) : (
                        <IndeterminateCheckBoxIcon
                          sx={{ color: red[600] }}
                        ></IndeterminateCheckBoxIcon>
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            md={8}
          >
            <Grafica
              refreshGraph={refreshGraph}
              data={id}
            />
            {hasPermission(PERMISSION_MODEL.readFinancialValue) == true && (
              <div className="w-full flex flex-col md:flex-row px-40 tickettypes">
                <div className="flex-col md:flex-row flex-1">
                  <Typography
                    className="text-16 leading-tight font-bold"
                    color="textSecondary"
                  >
                    {' '}
                    Ticket{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Fulfilled Qty: {metrics?.ticket_quantity ? metrics.ticket_quantity : 0}{' '}
                  </Typography>
                  <Typography>
                    Total Tickets: {metrics?.total_ticket_count ? metrics.total_ticket_count : 0}
                  </Typography>
                  <Typography>
                    {' '}
                    Fulfilled Employee Count:{' '}
                    {metrics?.fulfilled_employee_count ? metrics.fulfilled_employee_count : 0}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Fulfilled Owner Operator Count:{' '}
                    {metrics?.fulfilled_owner_operator_count
                      ? metrics.fulfilled_owner_operator_count
                      : 0}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Fulfilled Sub Contractor Count:{' '}
                    {metrics?.fulfilled_sub_contractor_count
                      ? metrics.fulfilled_sub_contractor_count
                      : 0}{' '}
                  </Typography>
                </div>
                <div className=" flex-col md:flex-row flex-1 justify-between px-3">
                  <Typography
                    className="text-16 leading-tight font-bold"
                    color="textSecondary"
                  >
                    {' '}
                    Hauling{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Hauling Sales: {metrics?.hauling_sales ? metrics.hauling_sales : '$0.00'}{' '}
                  </Typography>
                  <Typography>
                    Hauling Cost: {Houlingcost !== '$NaN' ? Houlingcost : '$0.00'}
                  </Typography>
                  <Typography>
                    {' '}
                    Employee Commissions:{' '}
                    {metrics?.employee_commissions
                      ? '$' + metrics.employee_commissions
                      : '$0.00'}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Subcontractor Costs:{' '}
                    {metrics?.sub_contractor_costs ? '$'+changeCurrency(formatter.format(metrics.sub_contractor_costs.split("$")[1])): '$0.00'}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Owner Operator Costs:{' '}
                    {metrics?.owner_operator_costs
                      ? formatter.format(changeCurrency(metrics.owner_operator_costs))
                      : '$0.00'}{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Hauling Revenue: ${houlingRevenue ? houlingRevenue : '0.00'}{' '}
                  </Typography>
                </div>
                <div className="flex-col md:flex-row flex-1 justify-between">
                  <Typography
                    className="text-16 leading-tight font-bold"
                    color="textSecondary"
                  >
                    {' '}
                    Material{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Material Sales: {metrics?.material_revenue
                      ? metrics.material_revenue
                      : '$0.00'}{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Material Cost:{' '}
                    {metrics?.material_cost2
                      ? formatter.format(metrics.material_cost2)
                      : '$0.00'}{' '}
                  </Typography>
                  <Typography className="md:px-0">
                    {' '}
                    Material Revenue : ${meterialRevenue ? meterialRevenue : '0.00'}{' '}
                  </Typography>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-end job-notes-btn">
                  <Button
                    className="px-20"
                    align="right"
                    onClick={(event) => handleClickOpen(id)}
                    type="submit"
                  >
                    {' '}
                    Notes{' '}
                  </Button>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid>
          <ModalCreateMaterials
            id={job_id ? job_id : id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          ></ModalCreateMaterials>
          <ModalReportTikets
            data={data}
            id={job_id ? job_id : id}
            openReport={openReport}
            setOpenReport={setOpenReport}
          ></ModalReportTikets>
          <Grid>
            <Dialog
              className="job-notes"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Notes'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {JsonGetById.j_notes}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="w-full flex flex-col md:flex-row flex-1"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Exit
                </Button>
              </DialogActions>
            </Dialog>

            <br />
            <div className="j-ticket">
              <div className="flex items-center flex-1 j-ticket-inner">
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="hidden sm:flex text-16 md:text-24 mx-0 font-bold justify-between items-center w-full"
                  color="inherit"
                >
                  Tickets
                  <div className='flex flex-row items-center'>
                    <div className='pr-32'>
                      <Tooltip title="Export CSV">
                          <IconButton onClick={(event) => handleClickOpenReport(job_id ? job_id : id)}>
                          <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          height="32" 
                          viewBox="0 -960 960 960" 
                          width="32">
                            <path d="M230-360h120v-60H250v-120h100v-60H230q-17 0-28.5 11.5T190-560v160q0 17 11.5 28.5T230-360Zm156 0h120q17 0 28.5-11.5T546-400v-60q0-17-11.5-31.5T506-506h-60v-34h100v-60H426q-17 0-28.5 11.5T386-560v60q0 17 11.5 30.5T426-456h60v36H386v60Zm264 0h60l70-240h-60l-40 138-40-138h-60l70 240ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/>
                          </svg>
                          </IconButton>
                      </Tooltip>

                    </div>

                    {hasPermission('ticket.create') && (
                      <button
                        className="add-ticket-btn"
                        onClick={openTicketForm}
                      >
                        {' '}
                        Add New Ticket{' '}
                      </button>
                    )}
                                
                    
                  </div>
                </Typography>
              </div>
              <Card
                style={{
                  marginTop: '10px',
                  padding: '0px 0px 0px 0px',
                  background: 'none',
                  boxShadow: 'none',
                  borderRadius: '0',
                }}
                className="mttabs"
              >
                <CardContent
                  style={{ padding: '0px', marginBottom: '10px' }}
                  className="newlayoutjotticket"
                >
                  <div className="flex flex-col md:flex-row items-end  tickets-header">
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      textColor="inherit"
                      variant="scrollable"
                      scrollButtons={false}
                      className="w-full md:w-3/5 justify-centerjobticket-btns"
                      classes={{ indicator: 'flex justify-center bg-transparent w-full' }}
                      TabIndicatorProps={{
                        children: (
                          <Box
                            sx={{ bgcolor: 'text.disabled' }}
                            className="w-full rounded opacity-0"
                          />
                        ),
                      }}
                    >
                      <Tab
                        value={0}
                        className="text-16 justify-center"
                        style={{ marginLeft: '0' }}
                        disableRipple
                        label="In-Progress"
                      />
                      <Tab
                        value={2}
                        className="text-16 justify-center"
                        disableRipple
                        label="Delayed"
                      />
                      <Tab
                        value={1}
                        className="text-16 justify-center "
                        disableRipple
                        label="Completed"
                      />
                      <Tab
                        value={3}
                        className="text-16 justify-center "
                        disableRipple
                        label="Deleted"
                      /> 
                    </Tabs>

                    {/**regular screen view view */}
                    <Hidden mdDown>
                      <div className='flex flex-row w-full  items-center justify-start lg:justify-end '>
                        <FormControl className='flex flex-col'>
                          <div className='flex flex-col'>
                            <FormLabel style={{color:'black'}}>Search Tickets by date and time:</FormLabel>
                            <div className='flex flex-row'>

                            
                             {/*  <RadioGroup
                              className='flex flex-row'
                                defaultValue="created"
                                value={ticketSearch}
                                onChange={(e) => {setTicketSearch(e.target.value)}}
                              >
                                <FormControlLabel
                                  value="created"
                                  control={<Radio />}
                                  label="Created"
                                  labelPlacement="top"
                                />
                                <FormControlLabel
                                  value="updated"
                                  control={<Radio />}
                                  label="Completed"
                                  labelPlacement="top"
                                />
                              </RadioGroup> */}
                        

                              <div className='flex'>
                                <Input
                                name="date1"
                                value={date1}
                                style={{color: 'black' }}
                                className='mr-16'
                                disableUnderline
                                fullWidth
                                type="datetime-local"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                setDate1(e.target.value );
                                }}
                                onKeyDown={(e) => {
                                e.preventDefault();
                                }}
                                />

                                <Input
                                name="date2"
                                value={date2}
                                style={{ color: 'black' }}
                                disableUnderline
                                fullWidth
                                type="datetime-local"
                                onChange={(e) => {
                                setDate2(e.target.value );
                                }}
                                onKeyDown={(e) => {
                                e.preventDefault();
                                }}
                                /> 
                              </div> 
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    </Hidden>

                    {/**mobile view */}
                    <Hidden mdUp>
                      <div className='flex flex-col w-full  items-center justify-start mt-12 '>
                        <FormControl>
                            <FormLabel style={{color:"#edca33"}}>Search Tickets by date and time:</FormLabel>
                             {/*  <RadioGroup
                              className='flex flex-row'
                                defaultValue="created"
                                value={ticketSearch}
                                onChange={(e) => {setTicketSearch(e.target.value)}}
                              >
                                <FormControlLabel
                                  value="created"
                                  control={<Radio />}
                                  label="Created"
                                  labelPlacement="top"
                                />
                                <FormControlLabel
                                  value="updated"
                                  control={<Radio />}
                                  label="Completed"
                                  labelPlacement="top"
                                />
                              </RadioGroup> */}
                        </FormControl>

                        <div className='flex'>
                          <Input
                          name="date1"
                          value={date1}
                          style={{color: 'black' }}
                          className='mr-16'
                          disableUnderline
                          fullWidth
                          type="datetime-local"
                          onChange={(e) => {
                            console.log(e.target.value);
                          setDate1(e.target.value );
                          }}
                          onKeyDown={(e) => {
                          e.preventDefault();
                          }}
                          />

                          <Input
                          name="date2"
                          value={date2}
                          style={{ color: 'black' }}
                          disableUnderline
                          fullWidth
                          type="datetime-local"
                          onChange={(e) => {
                          setDate2(e.target.value );
                          }}
                          onKeyDown={(e) => {
                          e.preventDefault();
                          }}
                          /> 
                        </div> 
                        
                      </div>
                    </Hidden>

                    <div className="flex w-1/2 items-center justify-center  px-0 jobticket-serch-nd-btns">
                        <div className="flex items-center w-full max-w-512 px-8 py-4 mx-8 rounded-16 shadow ticket-search">
                          <Input
                            style={{ height: '100%', color: 'black' }}
                            onChange={(e) =>{e.stopPropagation(); setSearch(e.target.value)}}
                            value={search}
                            placeholder="Search Tickets"
                            className="flex ml-8 "
                            disableUnderline
                            fullWidth
                            inputProps={{
                              'aria-label': 'Search Tickets',
                            }}
                            onKeyPress={(event) => {
                              event.stopPropagation();
                              if (event.key === 'Enter'){ onSearchClick()}
                            }}
                            autoFocus
                          />
                          <div
                            onClick={(e) => {e.stopPropagation(); onSearchClick()}}
                            className="cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="23"
                              viewBox="0 0 22 23"
                              fill="none"
                            >
                              <path
                                d="M21.6911 21.061L17.6125 16.8283C19.1957 14.763 19.9624 12.1416 19.7549 9.5031C19.5475 6.86457 18.3817 4.40946 16.4973 2.64261C14.6128 0.875762 12.153 -0.0685392 9.62348 0.00387725C7.09402 0.0762937 4.6872 1.15992 2.89792 3.03195C1.10865 4.90398 0.0729212 7.42211 0.00370586 10.0686C-0.0655094 12.715 0.83705 15.2886 2.5258 17.2602C4.21455 19.2318 6.56112 20.4515 9.08302 20.6686C11.6049 20.8856 14.1105 20.0835 16.0844 18.4271L20.13 22.6598C20.2322 22.7676 20.3538 22.8531 20.4878 22.9115C20.6217 22.9699 20.7654 23 20.9106 23C21.0557 23 21.1994 22.9699 21.3333 22.9115C21.4673 22.8531 21.5889 22.7676 21.6911 22.6598C21.8892 22.4453 22 22.1587 22 21.8604C22 21.5621 21.8892 21.2755 21.6911 21.061ZM9.9171 18.4271C8.39508 18.4271 6.90725 17.9549 5.64175 17.0702C4.37624 16.1855 3.3899 14.928 2.80745 13.4569C2.225 11.9857 2.07261 10.3668 2.36953 8.80501C2.66646 7.24321 3.39938 5.8086 4.47561 4.6826C5.55183 3.5566 6.92303 2.78979 8.41579 2.47912C9.90856 2.16846 11.4559 2.3279 12.862 2.93729C14.2682 3.54668 15.47 4.57864 16.3156 5.90267C17.1612 7.2267 17.6125 8.78335 17.6125 10.3757C17.6125 12.5111 16.8018 14.559 15.3586 16.0689C13.9154 17.5788 11.958 18.4271 9.9171 18.4271Z"
                                fill="black"
                              />
                            </svg>

                          </div>
                        </div>

                        
                      

                     {/*  <ThemeProvider>
                        <div className="tickets-btns flex">
                          <div className="px-2 py-0">
                            <div>
                              <Button
                                className="w-full flex flex-col md:flex-row flex-1"
                                onClick={(event) => handleClickOpenBatches(job_id ? job_id : id)}
                                type="submit"
                                variant="contained"
                                color="secondary"
                              >
                                Uninvoiced Tickets
                              </Button>
                            </div>
                          </div> 
                          <div className="px-2 py-0 pr-0">
                            <div>
                              <Button
                                className="w-full flex flex-col md:flex-row flex-1"
                                onClick={(event) => handleClickOpenReport(job_id ? job_id : id)} //
                                type="submit"
                                variant="contained"
                                color="secondary"
                              >
                                Export CSV
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ThemeProvider> */}
                    </div>
                      
                  </div>

                </CardContent>
              </Card>
            </div>
            <TicketsTable
              data={data}
              job_id={job_id}
              ticketStatus={selectedTab}
              jdestination={jdestination}
              quarrypit={quarrypit}
              search={search}
              date1={date1}
              date2={date2}
              onSearchDate={onSearchDate}
              ticketSearch={ticketSearch}
            />
          </Grid>
        </Grid>
        {/* ///////////////////////////////////added modal for add ticket///////////////////// */}
        <CreateTicketModal
          data={data}
          job_id={job_id}
          ticketStatus={selectedTab}
          open={modalShow}
          handleclose={handleClose}
          jobbilling={jobbilling}
        />
      </Container>

      {/**invoice dialog  */}
      <Dialog
        open={invoice && !createInvoice}
        onClose={() => {
          setCreateInvoice(false);
          setInvoice('');
          setSendWhen(true);
          setEmails([]);
        }}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        }}
        classes={{
          paper: 'm-24',
        }}
        maxWidth={'sm'}
      >
        {loading ? (
          <FuseLoading />
        ) : (
          <DialogContent>
            <DialogContentText color={'black'}>
              <div className="flex flex-row items-center mb-24 w-248">
                <div className="pr-10">
                  {/* <InfoOutlinedIcon sx={{color: 'red', fontSize:'40px'}} /> */}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.6132 6.9432C18.0893 4.35227 21.9105 4.35226 23.3866 6.9432L36.1735 29.3874C37.6081 31.9055 35.7408 35 32.7868 35H7.21306C4.25902 35 2.39176 31.9055 3.82637 29.3874L16.6132 6.9432ZM21.6665 28.3333C21.6665 29.2538 20.9203 30 19.9998 30C19.0793 30 18.3332 29.2538 18.3332 28.3333C18.3332 27.4129 19.0793 26.6667 19.9998 26.6667C20.9203 26.6667 21.6665 27.4129 21.6665 28.3333ZM21.2498 15C21.2498 14.3096 20.6902 13.75 19.9998 13.75C19.3095 13.75 18.7498 14.3096 18.7498 15V23.3333C18.7498 24.0237 19.3095 24.5833 19.9998 24.5833C20.6902 24.5833 21.2498 24.0237 21.2498 23.3333V15Z"
                      fill="#FF1414"
                    />
                  </svg>
                </div>
                <div className="text-red font-500 text-14">
                  Are you sure you want to batch all tickets and invoice them for this job? This
                  action cannot be undone.
                </div>
              </div>
            </DialogContentText>

            <div className="flex flex-row justify-center mx-6">
              <div className="p-12">
                <Button
                  style={{ backgroundColor: '#edca33' }}
                  onClick={() => {
                    setInvoice('');
                  }}
                >
                  <Typography
                    fontWeight={'bold'}
                    color={'black'}
                  >
                    Do Not Invoice
                  </Typography>
                </Button>
              </div>

              <div className="p-12">
                <Button
                  style={{ backgroundColor: '#FF0000' }}
                  onClick={() => {
                    //invoicejob(invoice)
                    if (sendwhen == true) {
                      setCreateInvoice(true);
                    }
                    if (sendwhen == false) {
                      setLoading(true);
                      invoicejob(invoice);
                    }
                  }}
                >
                  <Typography
                    fontWeight={'bold'}
                    color={'black'}
                  >
                    Create Invoice
                  </Typography>
                </Button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end">
              <Typography
                fontWeight={'bold'}
                color={'black'}
              >
                Send Invoice When Complete
              </Typography>
              <Checkbox
                checked={sendwhen}
                onChange={(e) => {
                  setSendWhen(e.target.checked);
                }}
              />
            </div>
          </DialogContent>
        )}
      </Dialog>

      {/**email dialog  */}
      <Dialog
        open={createInvoice}
        onClose={() => {
          setCreateInvoice(false);
          setInvoice('');
          setSendWhen(true);
          setEmails([]);
        }}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        }}
        classes={{}}
        fullWidth
      >
        {loading ? (
          <FuseLoading />
        ) : (
          <DialogContent>
            <DialogContentText
              fontWeight={'bold'}
              className=" flex flex-row justify-center items-center text-16 text-center pb-12"
              color={'black'}
            >
              <div className="flex flex-row items-center justify-center text-center w-full">
                <Typography
                  fontSize={16}
                  fontWeight={'bold'}
                  color={'black'}
                >
                  Enter Emails
                </Typography>
              </div>

              <div
                className="flex flex-row items-end justify-end"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCreateInvoice(false);
                  setInvoice('');
                  setSendWhen(true);
                  setEmails([]);
                }}
              >
                <CloseIcon sx={{ color: 'red' }} />
              </div>
            </DialogContentText>

            <ReactMultiEmail
              style={{ border: '2px solid #000000', borderRadius: '5px' }}
              placeholder="Input your Email Address"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails);
              }}
              getLabel={(email, index, removeEmail = index) => {
                return (
                  <Chip
                    className="mr-4"
                    data-tag-handle
                    label={email}
                    onDelete={() => removeEmail(index)}
                  />
                );
              }}
            />
            <div className="text-center pt-12">
              <Button
                variant="contained"
                onClick={() => {
                  setLoading(true);
                  invoicejob(invoice);
                }}
                className="mx-auto mt-8 cursor-pointer"
              >
                <Typography fontWeight={'bold'}>Send Invoice</Typography>
              </Button>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </ErrorBoundary>
  );
}

const mapStateToProps = (state) => {
  return {
    metrics: state.loadz_jobs_ticket?.JobTicketReducer?.metrics,
    jobInfo: state.loadz_jobs_ticket?.JobTicketReducer?.job,
    user: state.loadz_auth?.AuthReducer?.user,
    permission: state.loadz_auth?.AuthReducer?.user?.permission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAllTicketJobs: () => dispatch(JobTicketAction.clearAllTicketJobs()),
  searchJobTickets: (payload) => dispatch(JobTicketAction.searchJobTicket(payload)),
  searchJobTicketsDate: (payload) => dispatch(JobTicketAction.searchJobTicketDate(payload)),
  getMetrics: (payload) => dispatch(JobTicketAction.getMetrics(payload)),
  getJob: (payload) => dispatch(JobTicketAction.getJob(payload)),
  getAllTicketJobs: (payload, onError) => dispatch(JobTicketAction.getAllTicketJob(payload, onError)),
});

export default withReducer(
  'loadz_jobs_ticket',
  JobTicketReducer
)(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
