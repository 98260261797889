import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrintComponent from '../../PrintReport/PrintComponent';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { motion } from 'framer-motion';

const QuickDriverEarnings = ({open,handleClose,driverEarnings,driverTimesheets,selectedDriver}) => {

    const container = {
        show: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      };

    return (
        
        <Dialog 
            open={open} 
            onClose={handleClose}
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle>Driver Earnings</DialogTitle>
            <DialogContent>
                <FuseScrollbars className='overflow-y-auto flex-1'>
                    <div className=' w-full h-full'>
                        <motion.div ariants={container} initial='hidden' animate='show'>
                            {
                                driverEarnings.length && driverTimesheets.length && selectedDriver ?
                                <PrintComponent
                                    display={true}
                                    id={selectedDriver}
                                    timesheets={driverTimesheets}
                                    earnings={driverEarnings}
                                    batch_access={0}
                                />
                                : null
                            }
                            
                        </motion.div>
                    </div>
                </FuseScrollbars>
               
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
       
    );
};

export default QuickDriverEarnings;