import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { Divider, FormControl, Grid, Paper, Tooltip } from '@mui/material';
import moment from 'moment';
import { formatter, constants } from '../../../../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import ModelInvoiceEmail from '../../Uninvoiced/Modals/ModelInvoiceEmail';

import store from 'app/store';
import axios from 'axios';
import { getDataFromS3 } from 'app/main/CompanyList/companyFunctions';
import FuseLoading from '@fuse/core/FuseLoading';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PaymentDialog from './ViewPaymentModalShow';
import client from 'client';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { orderBy } from 'lodash';

const TODAY = moment();
const steps = [
  {
    label: 'Opend',
  },
  {
    label: 'Create an ad group',
  },
  {
    label: 'Create an ad',
  },
];

const item = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0 },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const token = localStorage.token;

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ModalGenerateInvoices({
  openModalInvoices,
  info,
  tickets,
  logo,
  compNameData,
  ClientName,
  compacctNumSub,
  user,
  prop_data=null,
}) {
  const [open, setOpen] = useState(false);
  const [uniqueJobs, setUniqueJobs] = useState([]);
  const [toTable, setTotable] = useState([]);
  const [dataForPDF, setDataWithSignature] = useState([]);
  const [logoCompany, setLogoCompany] = useState('');
  const [notes, setNotes] = useState();
  const [discounts, setDiscounts] = useState(0);
  const [terms, setTerms] = useState(0);
  const [dateStarted, setDateStarted] = useState(null);
  const [compacct, setCompacct] = useState('');
  const [compName, setCompName] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const [blobLogoForUNVoice, setBlobLogoForUNVoice] = useState(null);
  const TableToTable = toTable[0]?.customer?.jobs[0]?.batches[0];
  const [loaderData, setLoaderData] = useState(true);
  const [isViewPaymentOpened, setIsViewPaymentOpened] = useState(false);
  const [allPayment, setAllPayment] = useState(null);
  const [emailForPayment, setEmailForPayment] = useState(null);
  const [viewPaymentData, setViewPaymentData] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const dispatch = useDispatch();
  const handleOpen = (open) => {
    setOpen(!open);
  };
  const openViewPaymentDialog = (element) => {
    setIsViewPaymentOpened(true);
    setViewPaymentData(element);
  };
  const closeDialog = () => {
    setIsViewPaymentOpened(false);
  };

  useEffect(() => {
    openModalInvoices.current = handleOpen;
  }, []);

  useEffect(async () => {
    if (open) {
      let compName = prop_data ? prop_data?.compname : user?.company?.compname;

      if (compNameData) {
        compName = compNameData;
      }

      setCompName(compName);
      setCompacct(prop_data ? prop_data?.compacctnum : user?.company?.compacctnum);
    }
  }, [open]);

  useEffect(() => {
    //console.log('HERE AT THE OTHER OTHER OTHER MODULE ******', compacctNumSub);
    setTotable([]);

    if (open && compacct) {
      getData();
    }
  }, [compacct, open]);

  const getImage = async (url) => {
    const awsImage = await fetch(`${constants.URLLOCAL}/aws/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: `${token}`,
      },
      body: JSON.stringify({
        key: logo ? logo : url,
      }),
    });
    const img = await awsImage.json();

    return img.data?.fileLocation ? img.data.fileLocation : null;
  };

  const _showMessage = (message) => {
    dispatch(
      showMessage({
        message: message, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: 'error', //success error info warning null
      })
    );
  };

  const getData = async () => {
    try {
      setLoaderData(true);
      let _rows = [];
      setInfoData(info);
      const jobs = await client.post(
        `/invoices/tickets-invoices/${
          prop_data ? prop_data?.compacctnum : user?.company?.compacctnum
        }`,
        { tickets: tickets }
      );
      const jsonJobs = jobs.data;
      if (jsonJobs && jsonJobs.length > 0) {
        getEmailLogsForCustomer(jsonJobs[0].customer.id);
        if (jsonJobs[0] && jsonJobs[0].customer && jsonJobs[0].customer.id) {
          getPaymentLogs(jsonJobs[0].customer.id);
        }
        jsonJobs[0].customer.jobs.forEach((job, index) => {
          let dueDateSet = moment(TODAY, 'MM/DD/YYYY')
            .add(jsonJobs[0].customer.customerTerm, 'days')
            .format('MM/DD/YYYY');
          jsonJobs[0].customer.jobs[index].newDueDate = dueDateSet;
        });
      }

      if (jsonJobs && jsonJobs.length > 0) {
        if (jsonJobs[0].customer && jsonJobs[0].customer.jobs) {
          if (jsonJobs[0].customer.companyLogo) {
            jsonJobs[0].customer['blobResLOGO'] = jsonJobs[0].customer.companyLogo;
          }
        }
      }

      if (jsonJobs.msg) {
        setOpen(!open);
        setLoaderData(false);
        _showMessage(jsonJobs.msg);
        return;
      }
      _rows = jsonJobs;
      if (_rows && _rows.length > 0) {
        // const logo = await getImage(_rows[0].customer.companyLogo);
        let logo = null;
        if (_rows[0]?.customer?.companyLogo) {
          logo = _rows[0]?.customer?.companyLogo.includes('http')
            ? _rows[0].customer.companyLogo
            : `${constants.URLLOCAL}/aws/signed?image=${_rows[0].customer.companyLogo}`;
        }
        setLogoCompany(logo ? logo : 'assets/images/logos/loadzlogo.png');
        console.log('toTable as rows: ', _rows[0]);
        setTotable(_rows);

        const startDate = moment(info.dateCreated, 'YYYY-MM-DD');
        console.log('startDate', startDate);
        setDateStarted(info.dateCreated);
        const endDate = moment(info.dueDate, 'YYYY-MM-DD');
        console.log('endDate', endDate);
        const diffDays = moment.duration(endDate.diff(startDate)).asDays();
        setTerms(diffDays);
        setInvoiceNumber(info.invoice);
        setNotes(info.notes);
        setLoaderData(false);
        let dataWithSig = _rows;
        for (let j = 0; j < dataWithSig[0].customer.jobs.length; j++) {
          let tempIndex = 0;
          let ele = dataWithSig[0].customer.jobs[j];
          if (ele.batches && ele.batches.length > 0) {
            for (let index = 0; index < ele.batches.length; index++) {
              let batch = ele.batches[index];
              if (batch.info && batch.info.length > 0) {
                for (let i = 0; i < batch.info.length; i++) {
                  ele['calculateWaitTime'] = batch.info.reduce(
                    (total, item) => total + item.waitCR,
                    0
                  );
                  let ticket = batch.info[i];
                  ticket.printIndex = tempIndex;
                  tempIndex++;
                  let data = {
                    key: ticket.tkSignature,
                  };
                  let resp = await getDataFromS3(data);
                  if (resp) {
                    batch.info[i].newSignUrl = resp.data.data.fileLocation;
                    let blobRes = await getImageBlobFromsignature(resp.data.data.fileLocation);
                    if (blobRes) {
                      batch.info[i]['blobUrl'] = blobRes;
                    }
                  }
                }
              }
            }
          }
        }
        setDataWithSignature(dataWithSig);
      }
    } catch (e) {
      setLoaderData(false);
      console.log('invoice preview error', e);
    }
  };

  const getPaymentLogs = async (client_id) => {
    const finalRoute = typeof compacctNumSub === 'string' && compacctNumSub.includes('-OP') ?
    `/payments/ops/get-payments/customer/${client_id}/${
        prop_data ? prop_data.compacctnum : user?.company?.compacctnum
      }/0/${compacctNumSub}/${info.invoice}`
      : `/payments/get-payments/customer/${client_id}/${
        prop_data ? prop_data.compacctnum : user?.company?.compacctnum
      }/0`

    const payments = await client.get(finalRoute);
    if (payments.data) {
      const jsonPayments = payments?.data;
      let allPayment = [];
      jsonPayments &&
        jsonPayments?.payment &&
        jsonPayments?.payment?.length > 0 &&
        jsonPayments?.payment?.forEach((pd) => {
          if (pd.payments && pd.payments.length > 0) {
            let filterPayment = pd.payments.filter((pay) => pay.invoice_no == info.invoice);
            if (filterPayment && filterPayment.length > 0) {
              filterPayment.forEach((ftPay) => {
                if (allPayment.length == 0) {
                  allPayment.push(ftPay);
                } else {
                  let paymentIndex = allPayment.findIndex((alPay) => alPay.id == ftPay.id);
                  if (paymentIndex == -1) {
                    allPayment.push(ftPay);
                  }
                }
              });
            }
          }
        });
      setAllPayment(jsonPayments.payment);
    }
  };

  const getEmailLogsForCustomer = async (customer_id = null) => {
    const customerId = toTable[0]?.customer?.id ? toTable[0].customer.id : customer_id;
    if (customerId) {
      const finalRoute = typeof compacctNumSub === 'string' && compacctNumSub.includes('-OP') ?
        `/payments/ops/activity-logs/${customerId}/${
          prop_data ? prop_data?.compacctnum : user?.company?.compacctnum
        }/${info.invoice}/${compacctNumSub}`
        :
        `/payments/activity-logs/${customerId}/${
          prop_data ? prop_data?.compacctnum : user?.company?.compacctnum
        }/${info.invoice}`
      const emailLogsData = await client.get(finalRoute);
      if (emailLogsData.status != 200) {
        setEmailForPayment([]);
      }

      console.log('email logs *data', emailLogsData.data.emailLogs);
      if (emailLogsData.data.emailLogs && emailLogsData.data.emailLogs.length) {
        const filteredLogs = emailLogsData?.data?.emailLogs.filter((item) => {
          if (item?.client_id === customerId && item?.invoice_no === info.invoice.toString()) {
            return true;
          }
        });
        emailLogsData.data.emailLogs = filteredLogs;
        console.log('filtered *data', emailLogsData.data.emailLogs);
      }

      if (emailLogsData.data.emailLogs && emailLogsData.data.emailLogs.length) {
        emailLogsData.data.emailLogs = emailLogsData.data.emailLogs.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        emailLogsData.data.emailLogs.forEach((emailLog, index) => {
          let filteredPayments = emailLogsData.data.paymentsLog.filter(
            (payment) => new Date(payment.payment_date) > new Date(emailLog.created_at)
          );
          emailLog.paymentLogs = filteredPayments;
          if (index > 0) {
            emailLogsData.data.emailLogs[index - 1].paymentLogs = emailLogsData.data.emailLogs[
              index - 1
            ].paymentLogs.filter(
              (x) => !emailLogsData.data.emailLogs[index].paymentLogs.includes(x)
            );
          }
        });
        console.log('emailBasedData:===>', emailLogsData.data);

        emailLogsData.data.emailLogs.forEach((event) => {
          event.paymentLogs = orderBy(
            event.paymentLogs,
            (item) => moment(item?.payment_date).unix(),
            'asc'
          );
          return event;
        });

        setEmailForPayment(emailLogsData.data);
      } else {
        setEmailForPayment([]);
      }
    }
  };

  const componentRef = useRef();

  const [openEmail, setOpenEmail] = useState(false);

  const emailhandleOpen = () => {
    getEmailLogsForCustomer();
    setOpenEmail(false);
  };
  useEffect(() => {
    // setNotes(Array(uniqueJobs.length).fill(''))
    // setDiscounts(Array(uniqueJobs.length).fill(0));
    setDiscounts(info.discount ? info.discount.toFixed(2) : 0);
  }, [uniqueJobs]);

  const [storeLogo, setStoreLogo] = useState(null);
  const getS3Location = async (key) => {
    if (key != null && key != '') {
      let data = {
        key: store.getState().photo,
      };
      return await getDataFromS3(data);
    }
  };

  useEffect(async () => {
    let key = user?.company?.logo;
    if (logo) {
      key = logo;
    }
    let logoRes = await getS3Location(key);
    if (logoRes && logoRes.data && logoRes.data.data) {
      logoRes.data.data.logoKey = key;
      setStoreLogo(logoRes.data.data);
    }
  }, [user?.company?.logo]);

  async function getImageBlobFromUrl(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  React.useEffect(async () => {
    if (storeLogo && storeLogo.fileLocation) {
      const image = await getImageBlobFromUrl(storeLogo.fileLocation);
      setBlobLogoForUNVoice(image);
    }
  }, [storeLogo]);

  async function getImageBlobFromsignature(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const calculateDuration = (totalVal, waitVal) => {
    console.log('calculateDuration', totalVal, waitVal);
    const calculateDurationRes = Number(waitVal) / Number(totalVal);
    return `${calculateDurationRes.toFixed(1)}H`;
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth={'xl'}
        // onClose={() => handleOpen(open)}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth={true}
        className={loaderData ? 'customloader' : ''}
      >
        {loaderData ? (
          <div>
            <FuseLoading />
          </div>
        ) : (
          <>
            <BootstrapDialogTitle id='customized-dialog-title' onClose={() => handleOpen(open)}>
              Preview invoices
              <div className='invoicePrintbtns' style={{ marginTop: '15px' }}>
                <ReactToPrint
                  trigger={() => (
                    <div>
                      <button className='invoicePrintbtn' type='button'>
                        <img
                          width='20px'
                          height='20px'
                          src='/assets/images/icons/print-icon-white.svg'
                        />
                      </button>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
                <div>
                  {/* <PDFDownloadLink
                  document={<ModelCustomPdf toTable={toTable} />}
                  fileName={"PDF_REPORT.pdf"}
                  >{({ blob, url, loading, error }) =>
                  loading ? "Report loading..." : "Report ready to download"
                }
                </PDFDownloadLink> */}
                  {/* {dataForPDF && dataForPDF.length > 0 ? 
                  <PDFDownloadLink
                    document={
                      <ModelCustomPdf
                        toTable={dataForPDF}
                        compName={compName}
                        TableToTable={TableToTable}
                        invoiceDueDate={info.dueDate}
                        notes={notes}
                        blobLogoForUNVoice={blobLogoForUNVoice}
                        invoiceNumber={invoiceNumber}
                        infoData={infoData}
                        newLogo={logo}
                        terms={terms}
                        dateStarted={dateStarted}
                        info={info}
                      />
                    }
                    fileName={`${TableToTable?.info[0]?.job}-${
                      TableToTable?.info[0]?.job.length > 0
                        ? invoiceNumber + 1
                        : invoiceNumber
                    }-${moment().format("MM/DD/YYYY hh:mm:ss")}`}
                  >
                    <div
                      className="invoicePrintbtn"
                      style={{ marginRight: "0" }}
                    >
                      <img
                        width="20px"
                        height="20px"
                        src="/assets/images/icons/pdf-icon-white.svg"
                      />
                    </div>
                  </PDFDownloadLink> : 
                  <div
                      className="invoicePrintbtn"
                      style={{ marginRight: "0" }}
                    >
                      <CircularProgress  color="success"/>
                    </div>
                  } */}
                </div>
                <div className='invoicePrintbtn' onClick={() => setOpenEmail(true)}>
                  <img width='20px' height='20px' src='/assets/images/icons/email-icon-white.svg' />
                </div>
              </div>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div className='flex w-full max-w-screen-sx customer-left-bar xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col'>
                <div className='xl:w-1/3 lg:w-1/3 md:w-1/3   sm:w-full w-full justify-center items-center'>
                  <Card
                    component={motion.div}
                    variants={item}
                    style={{
                      border: '0',
                      borderRadius: '0',
                      padding: '0',
                      boxShadow: 'none',
                    }}
                  >
                    <Paper
                    // style={{ maxHeight: 750, overflow: 'auto' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          paddingRight: '30px',
                          paddingLeft: '20px',
                          paddingTop: '10px',
                        }}
                      >
                        {uniqueJobs.map((job, index) => (
                          <div>
                            <Typography variant='h6' gutterBottom>
                              Job No. {job}
                            </Typography>

                            <FormControl fullWidth sx={{ m: 1 }}>
                              <TextField
                                id={Math.random()}
                                label={`Due Date for ${job}`}
                                onChange={(e) => handleChangeTerms(e, index)}
                                variant='outlined'
                                type={'number'}
                              />
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1 }}>
                              <TextField
                                id={Math.random()}
                                label={'Discount'}
                                ariant='outlined'
                                onChange={(e) => handleChangeDiscount(e, index)}
                                type={'number'}
                              />
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1 }}>
                              <TextField
                                id={Math.random()}
                                label={'Deductions'}
                                variant='outlined'
                                type={'number'}
                              />
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1 }}>
                              <TextField
                                id={Math.random()}
                                label={'Notes'}
                                variant='outlined'
                                value={notes[index]}
                                onChange={(e) => handleChange(e, index)}
                                multiline
                                maxRows={4}
                              />
                            </FormControl>
                            <Divider />
                          </div>
                        ))}
                      </Box>
                    </Paper>
                  </Card>
                  <Card
                    ref={componentRef}
                    component={motion.div}
                    variants={item}
                    style={{
                      border: '1px solid #eacd2f',
                      borderRadius: '10',
                      padding: '30px',
                      boxShadow: 'none',
                    }}
                  >
                    <Paper className='InvoicesPrints left-bals shadow-none Invoices-nw pyment-box-td'>
                      {toTable[0]?.customer?.jobs?.map((job, index) => (
                        <div id={`pdf-invoice-${job.job}`}>
                          <Grid
                            className='mainpdfview'
                            container
                            spacing={2}
                            style={{ padding: '10px' }}
                          >
                            <Grid className='cstPdf' item xs={12}>
                              <Grid container spacing={2}>
                                <Typography
                                  variant='h5'
                                  gutterBottom
                                  component='div'
                                  className='text-sm font-medium w-full'
                                  style={{ fontSize: '15px' }}
                                >
                                  Balance due
                                </Typography>
                                <Typography
                                  variant='h4'
                                  gutterBottom
                                  component='div'
                                  className='m-0 sub-title w-full'
                                  style={{ fontSize: '20px', lineHeight: '1' }}
                                >
                                  {info && info.balance && info.balance}
                                </Typography>
                                <div className='mt-32 w-full'>
                                  <Typography
                                    variant='h5'
                                    gutterBottom
                                    component='div'
                                    className='text-sm font-medium w-full'
                                    style={{ fontSize: '15px' }}
                                  >
                                    Due Date
                                  </Typography>
                                  <Typography
                                    variant='h4'
                                    gutterBottom
                                    component='div'
                                    className='m-0 sub-title w-full'
                                    style={{
                                      fontSize: '18px',
                                      lineHeight: '1',
                                    }}
                                  >
                                    {moment(info.dueDate).format('MM/DD/YYYY')}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Paper>
                  </Card>
                  <Box className=' w-full pyment-box mt-16'>
                    <Typography
                      variant='h5'
                      gutterBottom
                      component='div'
                      className='text-sm font-medium w-full text-center mb-16'
                      style={{
                        fontSize: '16px',
                        paddingBottom: '15px',
                        borderBottom: '1px solid #eacd2f',
                      }}
                    >
                      Invoice Activity
                    </Typography>

                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className='font-medium'>
                          <p className='font-bold'>Opened </p>
                          {moment(info.dateCreated).format('MM/DD/YYYY')}
                        </TimelineContent>
                      </TimelineItem>

                      {emailForPayment &&
                        emailForPayment.emailLogs &&
                        emailForPayment.emailLogs.map((event, index) => {
                          return (
                            <div key={index}>
                              <TimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                {(event?.log_type == 'INVOICE_SENT' || !event?.log_type) && (
                                  <TimelineContent className='font-medium'>
                                    <p className='font-bold'>
                                      Invoice Email sent to{' '}
                                      <span style={{ color: 'blue' }}>
                                        {event?.send_to ? event?.send_to : ''}{' '}
                                      </span>{' '}
                                      on{' '}
                                    </p>

                                    {/* {moment(event.created_at).format("MM/DD/YYYY")} */}
                                    {moment(event.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                  </TimelineContent>
                                )}
                                {event?.log_type == 'INVOICE_SEEN' && (
                                  <TimelineContent className='font-medium'>
                                    <p className='font-bold'>Invoice Seen on </p>

                                    {/* {moment(event.created_at).format("MM/DD/YYYY")} */}
                                    {moment(event.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                  </TimelineContent>
                                )}
                              </TimelineItem>
                              <>
                                {event &&
                                  event.paymentLogs.map((element, index) => {
                                    return (
                                      <>
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                          </TimelineSeparator>
                                          <TimelineContent className='font-medium'>
                                            <p className='font-bold'>Payment Received </p>
                                            <p className='my-4'>
                                              {/* {moment(element.payment_date).format("MM/DD/YYYY" )}{" "} */}
                                              {moment(element.payment_date).format(
                                                'MMMM Do YYYY, h:mm:ss a'
                                              )}{' '}
                                            </p>

                                            <div className='flex pr-5'>
                                              <span>Amount Received: </span>
                                              <p className='text-sm ml-4'>
                                                {`${formatter.format(element.amount)}`}
                                              </p>
                                            </div>
                                            <p>Service Fee: </p>
                                            <p>
                                              <button
                                                className='text-blue-700'
                                                style={{ color: 'blue' }}
                                                onClick={() => openViewPaymentDialog(element)}
                                              >
                                                {' '}
                                                View Payment #{item.pdid}{' '}
                                              </button>
                                            </p>
                                          </TimelineContent>
                                        </TimelineItem>
                                      </>
                                    );
                                  })}
                              </>
                            </div>
                          );
                        })}

                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                        </TimelineSeparator>
                        <TimelineContent className='font-bold'>Status</TimelineContent>
                      </TimelineItem>
                    </Timeline>

                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>

                <div className='w-full ModalGenerateInvoicesPrints' style={{ padding: '10px' }}>
                  <Card
                    ref={componentRef}
                    component={motion.div}
                    variants={item}
                    style={{
                      // border: "0",
                      // borderRadius: "0",
                      padding: '20px',
                      // boxShadow: "none",
                    }}
                  >
                    <Paper className='InvoicesPrints Invoices-nw'>
                      {toTable[0]?.customer?.jobs?.map((job, index) => (
                        <div id={`pdf-invoice-${job.job}`} className='pdf_new'>
                          <Grid
                            className='mainpdfview'
                            container
                            spacing={2}
                            style={{ padding: '10px' }}
                          >
                            <Grid className='cstPdf' item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sx={{ margin: 'auto' }}>
                                  <img
                                    className='w-full-s'
                                    style={{ Width: 'auto', height: '95px' }}
                                    src={logoCompany}
                                    alt='logo'
                                  />
                                  <Typography variant='body1'>{compName}</Typography>
                                  <Typography variant='body1'>
                                    {job.comp_address}, {job.comp_city}, {job.comp_state},{' '}
                                    {job.comp_zip}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                                  <Typography
                                    variant='h2'
                                    gutterBottom
                                    component='div'
                                    className='font-bold m-0 mb-10 uppercase big-title'
                                    style={{ fontSize: '45px' }}
                                  >
                                    Invoice # {index === 0 ? invoiceNumber : invoiceNumber + index}
                                  </Typography>
                                  {/* <Typography variant="h6" gutterBottom component="div">Invoice Date: {moment(info.dateCreated).format('MM/DD/YYYY')}</Typography> */}
                                  <Typography
                                    variant='h5'
                                    gutterBottom
                                    component='div'
                                    className='flex justify-end items-end text-sm font-bold'
                                    style={{ fontSize: '16px' }}
                                  >
                                    Total:{' '}
                                    <Typography
                                      variant='h4'
                                      gutterBottom
                                      component='div'
                                      className='font-bold m-0 ml-10 sub-title'
                                      style={{
                                        fontSize: '25px',
                                        lineHeight: '1',
                                      }}
                                    >
                                      {formatter.format(
                                        (job?.jobTotalHauling ? job.jobTotalHauling : 0) +
                                          (job?.jobTotalMaterial ? job?.jobTotalMaterial : 0) +
                                          (job?.jobTotalWait ? job?.jobTotalWait : 0)
                                        //  +
                                        // (job.jobTotalTaxesMaterial
                                        //   ? job.jobTotalTaxesMaterial
                                        //   : 0)
                                      )}
                                    </Typography>
                                  </Typography>
                                  {/* <Typography variant="body1" >Terms: {terms}</Typography>
                                    <Typography variant="body1" >Due Date: {moment(info.dueDate).format('MM/DD/YYYY')}</Typography>
                                    <Typography variant="body1" ></Typography>
                                    <Typography variant="body1" >Hauling Total: {formatter.format(job.jobTotalHauling)}</Typography>
                                    <Typography variant="body1" >Material Total: {formatter.format(job.jobTotalMaterial)}</Typography>
                                  <Typography variant="body1" >Wait Charge: {formatter.format(job.jobTotalWait)}</Typography> */}
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={4} sx={{ margin: 'auto' }}>
                                  <Typography variant='body1' className='font-bold'>
                                    Bill to:
                                  </Typography>
                                  <Typography variant='body1'>
                                    {toTable[0]?.customer?.customerName}
                                  </Typography>
                                  <Typography variant='body1'>
                                    {toTable[0]?.customer?.locationId},
                                    {toTable[0]?.customer?.customerCity},
                                    {toTable[0]?.customer?.customerState},
                                    {toTable[0]?.customer?.customerZip}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ margin: 'auto' }}>
                                  {job?.po && (
                                    <div>
                                      <Typography variant='body1'>
                                        <strong className='font-bold'>Purchase Order: </strong>
                                        {job?.po}
                                      </Typography>
                                    </div>
                                  )}
                                  <Tooltip
                                    followCursor={true}
                                    title={job.og_job_no ? job.og_comp + '-' + job.og_job_no : ''}
                                    placement={'right'}
                                  >
                                    <Typography variant='body1'>
                                      <strong className='font-bold'>Job No: </strong>
                                      {job.job}
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant='body1'>
                                    <strong className='font-bold'>Job Site: </strong>
                                    {job.jobDestination}
                                  </Typography>
                                  <Typography variant='body1'>
                                    <strong className='font-bold'>Origin: </strong>
                                    {job.jobQuarry}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                                  <Typography variant='body1'>
                                    Date: {moment(info.dateCreated).format('MM/DD/YYYY')}
                                  </Typography>
                                  <Typography variant='body1'>
                                    Terms: {terms ? Number(terms).toFixed(0) : 0}
                                  </Typography>
                                  <Typography variant='body1'>
                                    Due Date: {moment(info.dueDate).format('MM/DD/YYYY')}
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* <br />
                                <Divider />
                              <br></br> */}

                              <Grid container>
                                {/* <Grid item xs={4} >
                                    <Typography variant="h6" gutterBottom component="div">
                                    Customer:
                                    </Typography>
                                    <Typography variant="body1" >
                                    {toTable[0]?.customer?.customerName}
                                    </Typography>
                                    <Typography variant="body1" >
                                    {toTable[0]?.customer?.locationId},{toTable[0]?.customer?.customerCity},{toTable[0]?.customer?.customerState},{toTable[0]?.customer?.customerZip}
                                    </Typography>
                                  </Grid> */}

                                {/* <Grid item xs={4}
                                    sx={{
                                      textAlign: 'center'
                                    }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                      {`Project (Job Name):`}
                                    </Typography>
                                    <Typography variant="body1" >
                                    {job.jobDestination}
                                    </Typography>
                                    <Typography variant="body1" >
                                    Job No.: {job.job}
                                    </Typography>
                                  </Grid> */}

                                {/* <Grid item xs={4}
                                    sx={{
                                      textAlign: 'end'
                                    }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                    {`Origin / Source:`}
                                    </Typography>
                                    <Typography variant="body1" >
                                    {job.jobQuarry}
                                    </Typography>
                                  </Grid> */}
                              </Grid>

                              <>
                                {job.batches.map((batch) => (
                                  <>
                                    <br></br>
                                    <Divider style={{ border: '1px solid #ccc' }}></Divider>
                                    <br />

                                    <Grid container className='topbatchno'>
                                      <Grid item xs={4}>
                                        <Typography variant='body1'>
                                          Batch No.: {batch.batch}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={4}>
                                        <Typography variant='body1' style={{ textAlign: 'center' }}>
                                          Date:{batch.batchDateTimeReal}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={4}>
                                        <Typography variant='body1' style={{ textAlign: 'right' }}>
                                          Material: {batch.material}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {/* <br></br>
                                        <Divider style={{border: '1px solid #000',}}></Divider> */}
                                    <br></br>
                                    <Grid container>
                                      <Grid item xs={12} className='newinvoice-ui'>
                                        <Paper
                                          sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                          }}
                                        >
                                          <Table
                                            style={{
                                              width: '100%',
                                              tableLayout: 'auto',
                                              border: '1px solid #000',
                                              borderCollapse: 'collapse',
                                            }}
                                          >
                                            <TableBody>
                                              {['Hauling', 'Material', 'Wait Charge'].map(
                                                (type, typeIndex) => (
                                                  <>
                                                    <></>
                                                    {batch.info.map((ticket, i) => (
                                                      <>
                                                        {batch.info.length + 1 === i + 1 ? (
                                                          <></>
                                                        ) : (
                                                          <>
                                                            {typeIndex === 0 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.ticketCR > 0
                                                                  ) && (
                                                                    <>
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align='left'
                                                                          width='100%'
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 10px',
                                                                            border:
                                                                              '1px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableHead
                                                                        width='100%'
                                                                        style={{
                                                                          display: 'contents',
                                                                        }}
                                                                      >
                                                                        <TableRow>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Ticket No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Scale No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Truck
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Rate
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Qty
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Unit
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            TAX
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Total
                                                                          </TableCell>
                                                                          {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                        </TableRow>
                                                                      </TableHead>
                                                                    </>
                                                                  )}
                                                                {ticket.ticketCR &&
                                                                ticket.ticketCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {/*job.jobUnitRate*/}
                                                                      {(ticket.ticketCR/ticket.qty).toFixed(2)}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.qty}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {typeIndex != 2
                                                                        ? batch.unit
                                                                        : 'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {'N'}
                                                                    </TableCell>

                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.ticketCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : typeIndex === 1 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.materialCR > 0
                                                                  ) && (
                                                                    <>
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align='left'
                                                                          width='100%'
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 10px',
                                                                            border:
                                                                              '1px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableHead
                                                                        width='100%'
                                                                        style={{
                                                                          display: 'contents',
                                                                        }}
                                                                      >
                                                                        <TableRow>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Ticket No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Scale No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Truck
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Rate
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Qty
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Unit
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            TAX
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Total
                                                                          </TableCell>
                                                                          {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                        </TableRow>
                                                                      </TableHead>
                                                                    </>
                                                                  )}
                                                                {ticket.materialCR &&
                                                                ticket.materialCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {job.jobMaterialRate}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.qty}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {typeIndex != 2
                                                                        ? batch.unit
                                                                        : 'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {job.jobTaxable ? 'T' : 'N'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.materialCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : typeIndex === 2 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.waitCR > 0
                                                                  ) && (
                                                                    <>
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align='left'
                                                                          width='100%'
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 10px',
                                                                            border:
                                                                              '1px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableHead
                                                                        width='100%'
                                                                        style={{
                                                                          display: 'contents',
                                                                        }}
                                                                      >
                                                                        <TableRow>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Ticket No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Scale No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Truck
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Rate
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Duration
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Unit
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            TAX
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align='center'
                                                                            width='11.11%'
                                                                          >
                                                                            Total
                                                                          </TableCell>
                                                                          {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                        </TableRow>
                                                                      </TableHead>
                                                                    </>
                                                                  )}
                                                                {ticket.waitCR &&
                                                                ticket.waitCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {job.waitrate}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {calculateDuration(
                                                                        job.waitrate,
                                                                        ticket.waitCR
                                                                      )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {'N'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align='center'
                                                                      width='11.11%'
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.waitCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    ))}
                                                  </>
                                                )
                                              )}

                                              {/* <TableRow>
                                                    <TableCell>Sub Totals</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell align="center">{`${Number(batch.qtyTotal).toFixed(2)}`}</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell align="center">{`${formatter.format(batch.totalMaterialCost + batch.totalTicketCost + batch.totalWaitTime)}`}</TableCell>
                                                    <TableCell></TableCell>
                                                  </TableRow> */}
                                            </TableBody>
                                          </Table>
                                        </Paper>
                                      </Grid>
                                    </Grid>
                                  </>
                                ))}
                              </>
                              <br></br>
                              {/* <Grid container>
                                  <Grid item xs={12}>
                                    <Paper sx={{ width: '100%', borderRadius:'0', boxShadow: 'none' }}>
                                      <Table className='table-bottom' style={{ width: "100%", tableLayout: "auto" }}>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>

                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%">Total QTY</TableCell>

                                            <TableCell align="center" width="22.22%">{`${Number(job.jobTotalTickets).toFixed(2)}`}</TableCell>

                                            <TableCell align='center' width="0%"></TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>

                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%">Total Wait Time</TableCell>

                                            <TableCell align="center" width="22.22%">{`${Number(job.jobTotalWaitTime).toFixed(2)}`} H</TableCell>

                                            <TableCell align='center' width="0%"></TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell align="center" width="11.11%"></TableCell>
                                            <TableCell align="center" width="11.11%"></TableCell>
                                            <TableCell align="center" width="11.11%"></TableCell>
                                            <TableCell align="center" width="11.11%"></TableCell>
                                            <TableCell align="center" width="11.11%"></TableCell>

                                            <TableCell align="center" width="0%"></TableCell>
                                            <TableCell align="center" width="22.22%">Sub Total Invoice</TableCell>

                                            <TableCell align="center" width="22.22%">{`${formatter.format(
                                              job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait
                                            )}`}</TableCell>

                                            <TableCell align="center" width="0%"></TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>

                                            <TableCell align='center' width="0%"></TableCell>
                                            <TableCell align='center' width="22.22%">Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : `8.25%` : 0}% Material</TableCell>

                                            <TableCell align="center" width="22.22%">{`${formatter.format(
                                              job.jobTotalTaxesMaterial
                                            )}`}</TableCell>

                                            <TableCell align='center' width="0%"></TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%"></TableCell>

                                            <TableCell align='center' width="11.11%"></TableCell>
                                            <TableCell align='center' width="11.11%">Total Invoice</TableCell>

                                            <TableCell align="center" width="22.22%">{`${formatter.format(
                                              job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait
                                            )}`}</TableCell>

                                            <TableCell align='center' width="0%"></TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Grid>
                                </Grid> */}
                              <br></br>
                              {/* <div className='page-break'></div> */}
                              <Grid container spacing={2} className='new-invoice-bottmtable'>
                                <Grid item xs={7} className='new-invoice-bottmleft'>
                                  <Typography variant='body1'>Notes to customer:</Typography>
                                  <Typography variant='body1' sx={{ wordBreak: 'break-all' }}>
                                    {' '}
                                    {notes}{' '}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} className='new-invoice-bottmright'>
                                  <Table>
                                    <TableRow>
                                      <TableCell align='left'>Ticket Count</TableCell>
                                      <TableCell align='left'>{`${Number(
                                        job.jobTotalTickets
                                      ).toFixed(2)}`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left'>Total QTY</TableCell>
                                      <TableCell align='left'>
                                        {`${job.batches
                                          .reduce(
                                            (total, batch) => total + (Number(batch.qtyTotal) || 0),
                                            0
                                          )
                                          .toFixed(2)}`}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left'>Total Wait Time</TableCell>
                                      <TableCell align='left'>
                                        {`${Number(
                                          job &&
                                            job.calculateWaitTime &&
                                            Number(job.calculateWaitTime) / Number(job.waitrate)
                                        ).toFixed(1)}`}
                                        H
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left'>Sub Total Invoice</TableCell>
                                      <TableCell align='left'>{`${formatter.format(
                                        (job.jobTotalHauling ? job.jobTotalHauling : 0) +
                                          ((job.jobTotalMaterial ? job.jobTotalMaterial : 0) -
                                            (job.jobTotalTaxesMaterial
                                              ? job.jobTotalTaxesMaterial
                                              : 0)) +
                                          (job.jobTotalWait ? job.jobTotalWait : 0)
                                      )}`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left'>
                                        Tax{' '}
                                        {job.jobTaxable
                                          ? job.jobTax > 0 || job.jobTax
                                            ? job.jobTax
                                            : `8.25%`
                                          : 0}
                                        % Material
                                      </TableCell>
                                      <TableCell align='left'>{`${formatter.format(
                                        job.jobTotalTaxesMaterial ? job.jobTotalTaxesMaterial : 0
                                      )}`}</TableCell>
                                    </TableRow>

                                    {info && info.balance ? (
                                      <TableRow>
                                        <TableCell align='left' style={{ fontWeight: 'bold' }}>
                                          Balance Due
                                        </TableCell>
                                        <TableCell align='left' style={{ fontWeight: 'bold' }}>
                                          {info.balance}
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <TableRow>
                                        <TableCell align='left' style={{ fontWeight: 'bold' }}>
                                          Total Invoice
                                        </TableCell>
                                        <TableCell
                                          align='left'
                                          style={{ fontWeight: 'bold' }}
                                        >{`${formatter.format(
                                          (job.jobTotalHauling ? job.jobTotalHauling : 0) +
                                            (job.jobTotalMaterial ? job.jobTotalMaterial : 0) +
                                            (job.jobTotalWait ? job.jobTotalWait : 0)
                                        )}`}</TableCell>
                                      </TableRow>
                                    )}
                                  </Table>
                                </Grid>
                              </Grid>
                              <br></br>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    textAlign: '-webkit-right',
                                  }}
                                  className='flex justify-end items-end'
                                >
                                  <Typography variant='h6' style={{ fontSize: '14px' }}>
                                    Powered by
                                  </Typography>
                                  <img
                                    className='pdf_lodz_logo'
                                    src='assets/images/logos/loadzlogo.png' //TODO Configurable Variable.
                                    alt='logo'
                                  />
                                </Grid>
                              </Grid>
                              <br></br>
                              <br></br>
                              <Divider style={{ border: '1px solid #ccc' }}></Divider>
                              <br></br>
                            </Grid>
                          </Grid>
                          {/* <div className='break-before-all'></div>
                            <div className='break-after-all'></div> */}
                        </div>
                      ))}
                    </Paper>
                  </Card>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              {/* {true &&
                <Button autoFocus onClick={() => sendData()}>
                  Generate
                </Button>
              } */}
              <Button autoFocus onClick={() => handleOpen(open)}>
                Close
              </Button>
            </DialogActions>
            {openEmail ? (
              <ModelInvoiceEmail
                prop_data={prop_data}
                open={openEmail}
                emailhandleOpen={emailhandleOpen}
                toTable={toTable}
                invoiceDate={info.dateCreated}
                infoData={infoData}
                compName={compName}
                invoiceNo={invoiceNumber}
                terms={terms}
                tickets={tickets}
                info={info}
                allPayment={allPayment}
                compacctNumSub={compacctNumSub}
              />
            ) : null}
          </>
        )}

        <PaymentDialog
          prop_data={prop_data}
          isOpen={isViewPaymentOpened}
          paymentData={viewPaymentData}
          closeModal={closeDialog}
          toTable={toTable}
          ClientName={ClientName}
        />
      </BootstrapDialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};
export default connect(mapStateToProps, null)(ModalGenerateInvoices);
