import React, {useEffect} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, {tableRowClasses} from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import AppBar from '@mui/material/AppBar';
import { color } from '@mui/system';
import {header, footer, Report, job, halfborder} from './Report.css'
import store from 'app/store';
import Tooltip from '@mui/material/Tooltip';
import ErrorBoundary from '../ErrorBoundary';
const classes= {
    paper: {
       border: 'none',
       boxShadow: 'none',
       height: '80%',
       width: '95%',
       padding: 0,
       //backgroundColor: '#2549d9',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       background: 'none'
    },
    blank: {
       height: '0%',
       width: '0%',
       padding: 0,
       alignItems: 'center',
       justifyContent: 'center',
       display: 'none'
    },
}

var ReportData= []
/**
 * [
 * {
 *      name
 *      id
 *      time_data:fake array[{name, days,[{shifts}]}]
 *      earnings_data:[{job_no, [{tickets in the job_no}]}]
 *      day_totals:[{day:'sunday', sunday:'10:10'},{day:'monday', monday:'10:10'}]
 * }
 * ]
 */

const editNums = (num) => {
    var newnum = num
    if(num.split(".").length == 2)
    {
        if(num.split(".")[1].length < 2)
        {newnum = num+"0"}
    }
    else
    {
        newnum = num+".00"
    }

    return newnum
}


const createData = (earnings,timesheets,batch_access) => {



    var general_obj = {}

    var data = []
    var time_data = []
    console.log('start of createData function')
    console.log("data 1",earnings)
    console.log("data 2",timesheets)
    console.log("data 3",batch_access)
    console.log('end of createData function')
    //date
    if(earnings?.batches?.at(batch_access)?.batch.commission && earnings?.batches?.at(batch_access)?.batch?.commission.date)
    {
        for(const day of earnings.batches.at(batch_access).batch.commission.date)
        {
            for(const job of earnings.batches.at(batch_access).batch.commission[day].job_no)
            {
                const found = data.find(elem => elem.job_no == job)
                if(found)
                {
                    const idx = data.indexOf(found)
                    const job_access = data[idx].job_no

                    for(const tk of earnings.batches.at(batch_access).batch.commission[day][job].tk_no)
                    {
                        data[idx][job_access].push(earnings.batches.at(batch_access).batch.commission[day][job][tk])
                    }
                }
                else
                {
                    const newjob = {}
                    newjob.job_no = job
                    newjob.details = earnings.batches.at(batch_access).batch.commission[day][job].details

                    newjob[job] = []
                    for(const tk of earnings.batches.at(batch_access).batch.commission[day][job].tk_no)
                    {
                        newjob[job].push(earnings.batches.at(batch_access).batch.commission[day][job][tk])
                    }

                    data.push(newjob)
                }
            }
        }
    }

    var newtime = {}
    newtime.days = timesheets?.batches?.at(0)?.days
    newtime.name = timesheets?.name

    if(timesheets?.batches.at(0)?.batch?.shift_data)
    {
        if(timesheets?.batches?.at(0)?.batch?.shift_data.date)
        {newtime.shifts = []

        for(const day of timesheets?.batches?.at(0)?.batch?.shift_data.date)
        {
            for(const shift of timesheets?.batches?.at(0)?.batch.shift_data[day])
            {
                newtime.shifts.push(shift)
            }
        }}
        else{newtime.shifts = false}
    }
    else{newtime.shifts = false}

    time_data.push(newtime)

    for(const elem of data)
    {
        var job_total = 0
        for(const job of elem[elem.job_no])
        {
            job_total = job_total + (+job.commission)
        }
        elem.total = job_total.toFixed(2)
    }

    if(timesheets?.batches?.at(0)?.batch?.rate)
    {
        general_obj.complogo = timesheets?.complogo
        general_obj.driver_address = timesheets?.driver_address
        general_obj.commission_percent =  timesheets?.commission_percent ? timesheets?.commission_percent+"%" : 0+"%"
        general_obj.name = timesheets?.name
        const batch_access_data = timesheets?.batches?.at(0)
        general_obj.ot_hours = batch_access_data?.batch.ot_hours
        general_obj.ot_minutes = batch_access_data?.batch.ot_minutes
        general_obj.ot_pay = batch_access_data?.batch.ot_pay
        general_obj.total_pay = batch_access_data?.batch.pay
        general_obj.rate = batch_access_data?.batch?.rate
        general_obj.reg_hours = batch_access_data?.batch?.regular_hours
        general_obj.reg_minutes = batch_access_data?.batch?.regular_minutes
        general_obj.commission_total = earnings.batches.at(batch_access).commission_total
        general_obj.data = data
        general_obj.total_hours = batch_access_data.batch.total_hours
        general_obj.drive_time = batch_access_data.batch.drive_time
        general_obj.time_data = time_data
        general_obj.day_summary = batch_access_data?.day_summary
        general_obj.days = batch_access_data.days
        general_obj.additions = earnings?.batches?.at(batch_access)?.batch.additions
        general_obj.additions_total = +earnings?.batches?.at(batch_access)?.batch.additions_total
        general_obj.net_money = earnings?.batches?.at(batch_access)?.batch?.net_money == undefined ? 0 : +earnings?.batches?.at(batch_access)?.batch.net_money
        general_obj.first_shift = earnings?.batches?.at(batch_access)?.batch?.first_shift

        general_obj.deductions = earnings?.batches?.at(batch_access)?.batch?.deductions
        general_obj.deductions_total = +earnings?.batches?.at(batch_access)?.batch?.deductions_total

        
    }
    else
    {
        general_obj.complogo = timesheets?.complogo
        general_obj.driver_address = timesheets?.driver_address
        general_obj.commission_percent = timesheets?.commission_percent ? timesheets?.commission_percent+"%" : 0+"%"
        general_obj.name = timesheets?.name
        general_obj.ot_hours = "00"
        general_obj.ot_minutes ="00"
        general_obj.ot_pay = 0
        general_obj.total_pay =0
        general_obj.rate = timesheets?.batches?.at(0)?.rate
        general_obj.reg_hours =0
        general_obj.reg_minutes =0
        general_obj.commission_total = earnings?.batches?.at(batch_access)?.commission_total
        general_obj.data = data
        general_obj.total_hours = "00:00"
        general_obj.drive_time = false
        general_obj.time_data = time_data
        const batch_access_data = timesheets?.batches?.at(0)
        general_obj.day_summary = batch_access_data?.day_summary
        general_obj.days = batch_access_data?.days
        general_obj.additions = earnings.batches.at(batch_access).batch.additions
        general_obj.additions_total = +earnings?.batches?.at(batch_access)?.batch?.additions_total
        general_obj.net_money = earnings?.batches?.at(batch_access)?.batch?.net_money == undefined ? 0 : +earnings?.batches?.at(batch_access)?.batch.net_money
        general_obj.first_shift = earnings?.batches?.at(batch_access)?.batch?.first_shift

        general_obj.deductions = earnings?.batches?.at(batch_access)?.batch?.deductions
        general_obj.deductions_total = +earnings?.batches?.at(batch_access)?.batch?.deductions_total

    }

    ReportData.push(general_obj)

}


const JobTable = ({data}) => {//data holds the sub tables data.details.og_job_no and data.details.og_comp
var job_total = 0
    return (
        <div className='flex flex-col items-center justify-center' >
            <Box width={684} sx={{marginTop: 1}}>
							{data.map((elem ,i) => {
								return(
                                    <ErrorBoundary>
                                        <Box className='job' border={2} borderBottom={0} sx={{marginBottom: 1}} key={i}>
                                            <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "white"}}>
                                                    <div style={{paddingLeft: '25px',marginRight: '50px', width: '500px'}}>
                                                            <Tooltip title={data.details?.og_job_no ? data.details?.og_comp+"-"+data.details?.og_job_no : ""} followCursor={true} >
                                                                    <Typography fontWeight={'bold'} fontSize={17}>
                                                                            {elem.job_no} &nbsp; {elem.details.destination} &nbsp; {elem.details.commissions ? null : "(pay by the hour)"}
                                                                    </Typography>
                                                            </Tooltip>
                                                    </div>


                                                    <div style={{width:'140px'}}>
                                                            <Typography fontWeight={'bold'} fontSize={17}>
                                                                    Total: ${editNums((+elem.total).toLocaleString())}
                                                            </Typography>
                                                    </div>


                                            </AppBar>

                                            <Table  sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2, paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                                                <TableHead >

                                                    <TableRow style={{backgroundColor: "#2c2e35"}}>
                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Ticket #</TableCell>
                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Scale #</TableCell>
                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Date</TableCell>
                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Material</TableCell>
                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Unit</TableCell>
                                                            {
                                                                    elem.details.commissions ?
                                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>QTY</TableCell>
                                                                    :
                                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Duration</TableCell>
                                                            }
                                                            {
                                                                    elem.details.commissions ?
                                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Unit Cost</TableCell>
                                                                    :
                                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Rate</TableCell>
                                                            }

                                                            <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Total</TableCell>
                                                            <TableCell width={'30px'} sx={{color: 'white', paddingLeft: 0}}></TableCell>

                                                    </TableRow>
                                                </TableHead>

                                                <TableBody >
                                                    {
                                                        elem[elem.job_no].map((instance, i) => {
                                                            return(
                                                            <ErrorBoundary>
                                                                <TableRow key = {i}>
                                                                    <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{instance.tk_no}</TableCell>
                                                                    <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.scale_no}</TableCell>
                                                                    <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{moment(instance.complete_date.split("T")[0]).format("MM/DD")}</TableCell>
                                                                    <TableCell align='left' width={'90px'} sx={{marginLeft: 5, paddingLeft: 0}}>{elem.details.material}</TableCell>
                                                                    <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{elem.details.unit}</TableCell>
                                                                    {
                                                                                elem.details.commissions ?
                                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.qty}</TableCell>
                                                                                :
                                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.duration}</TableCell>

                                                                    }
                                                                    {
                                                                                elem.details.commissions ?
                                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{editNums((+instance.unit_cost).toLocaleString())}</TableCell>
                                                                            :
                                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{editNums((+elem.details.job_hourly_rate).toLocaleString())}</TableCell>

                                                                    }
                                                                    <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>${editNums((+instance.commission).toLocaleString())}</TableCell>
                                                                    {
                                                                                instance.wait_time ?
                                                                                <TableCell align='left' width={'30px'} sx={{paddingLeft: 0}}>WT</TableCell>
                                                                                :
                                                                                <TableCell align='left' width={'30px'} sx={{paddingLeft: 0}}></TableCell>
                                                                    }
                                                                </TableRow>
                                                            </ErrorBoundary>
                                                            )
                                                        })
                                                    }
                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </ErrorBoundary>
								)
							})}
            </Box>
        </div>
    )
}

const AdditionsTable = ({data}) => {
    return (
        data.additions.bonus.length > 0 ?
        <div className='flex flex-col items-center justify-center' >
        <ErrorBoundary>
            <Box width={684} sx={{marginTop: 1}}>
                <Box className='job' border={2} borderBottom={0} sx={{marginBottom: 1}}>
                    <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "white", borderBottom: 2}}>
                        <div style={{paddingLeft: '25px',marginRight: '10px', width: '140px'}}>
                            <Typography fontWeight={'bold'} fontSize={17}>
                                Additions
                            </Typography>
                        </div>
                    </AppBar>

                    <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "white"}}>
                        <div style={{paddingLeft: '25px',marginRight: '10px', width: '140px'}}>
                            <Typography fontWeight={'bold'} fontSize={17}>
                                {moment(data.additions.start).format("MMM DD, YYYY")}
                            </Typography>
                        </div>
                        <div style={{width:'32px'}}>
                            <Typography fontWeight={'bold'} fontSize={17}>
                                to
                            </Typography>
                        </div>
                        <div style={{width:'140px'}}>
                            <Typography fontWeight={'bold'} fontSize={17}>
                                {moment(data.additions.end).format("MMM DD, YYYY")}
                            </Typography>
                        </div>
                    </AppBar>
            <Table  sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2, paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                <TableHead >

                    <TableRow style={{backgroundColor: "#2c2e35"}}>
                        <TableCell width={'30px'} sx={{color: 'white', paddingLeft: '8px'}}>Entry Date</TableCell>
                        <TableCell width={'200px'} sx={{color: 'white', paddingLeft: 0}}>Reason</TableCell>
                        <TableCell width={'30px'} sx={{color: 'white', paddingLeft: 0}}>Amount</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody >
                    {
                        data.additions.bonus.map((elem, i) => {
                            return(<TableRow key = {i}>
                                        <TableCell align='left' width={'10px'} sx={{paddingLeft: '8px'}}>{moment(elem.entry_date.split("T")[0]).format("MM/DD")}</TableCell>
                                        <TableCell align='left' width={'200'} sx={{paddingLeft: 0}}>{elem.reason}</TableCell>
                                        <TableCell align='left' width={'30px'} sx={{paddingLeft: 0}}>${editNums((+elem.pay).toLocaleString())}</TableCell>
                                    </TableRow>
                            )
                        })
                    }
                </TableBody>

            </Table>
                </Box>
            </Box>
        </ErrorBoundary>
    </div>
        :
        null
    )
}

const DeductionsTable = ({data}) => {
    return (
        data.deductions.unbonus.length > 0 ?
        <div className='flex flex-col items-center justify-center' >
            <ErrorBoundary>
                <Box width={684} sx={{marginTop: 1}}>
                    <Box className='job' border={2} borderBottom={0} sx={{marginBottom: 1}}>
                        <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "white", borderBottom: 2}}>
                            <div style={{paddingLeft: '25px',marginRight: '10px', width: '140px'}}>
                                <Typography fontWeight={'bold'} fontSize={17}>
                                    Deductions
                                </Typography>
                            </div>
                        </AppBar>

                        <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "white"}}>
                            <div style={{paddingLeft: '25px',marginRight: '10px', width: '140px'}}>
                                <Typography fontWeight={'bold'} fontSize={17}>
                                    {moment(data.deductions.start).format("MMM DD, YYYY")}
                                </Typography>
                            </div>
                            <div style={{width:'32px'}}>
                                <Typography fontWeight={'bold'} fontSize={17}>
                                    to
                                </Typography>
                            </div>
                            <div style={{width:'140px'}}>
                                <Typography fontWeight={'bold'} fontSize={17}>
                                    {moment(data.deductions.end).format("MMM DD, YYYY")}
                                </Typography>
                            </div>
                        </AppBar>
                <Table  sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2, paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                    <TableHead >

                        <TableRow style={{backgroundColor: "#2c2e35"}}>
                            <TableCell width={'30px'} sx={{color: 'white', paddingLeft: '8px'}}>Entry Date</TableCell>
                            <TableCell width={'200px'} sx={{color: 'white', paddingLeft: 0}}>Reason</TableCell>
                            <TableCell width={'30px'} sx={{color: 'white', paddingLeft: 0}}>Amount</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            data.deductions.unbonus.map((elem, i) => {
                                return(<TableRow key = {i}>
                                            <TableCell align='left' width={'10px'} sx={{paddingLeft: '8px'}}>{moment(elem.entry_date.split("T")[0]).format("MM/DD")}</TableCell>
                                            <TableCell align='left' width={'200'} sx={{paddingLeft: 0}}>{elem.reason}</TableCell>
                                            <TableCell align='left' width={'30px'} sx={{paddingLeft: 0}}>(${editNums((+elem.pay).toLocaleString())})</TableCell>
                                        </TableRow>
                                )
                            })
                        }
                    </TableBody>

                </Table>
                    </Box>
                </Box>
            </ErrorBoundary>
        </div>
        :
        null
    )
}

const DayTotals_BlocksFormat = (weekday,date,totals,data) => {
    var block_name = ''
    var hours = ''
   if(date)
    {var found = totals.find(elem => elem.day == date)
    if(found)
    {
        block_name = found.week_day
        let total_hours = +found.regular_time.split(":")[0] + (+found.over_time.split(":")[0])
        let total_minutes = +found.regular_time.split(":")[1] + (+found.over_time.split(":")[1])
        while(total_minutes >= 60)
        {
            total_minutes = total_minutes - 60
            total_hours = total_hours + 1
        }
        hours = total_hours+":"+total_minutes
    }
    else{
        block_name = moment(date).format('dddd')
        hours = '00:00'
    }
    }
    if(weekday == 'Total')
    {
        block_name = "Total"
        hours = data.total_hours
    }
    if(weekday == 'OverTime')
    {
        block_name = "OverTime"
        hours = data.ot_hours+":"+data.ot_minutes
    }

    return(
        <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none",padding: 0, margin: 0, color: 'white', alignContent: 'center'}}}>
            <TableHead >
                    <TableRow >
                        {
                            weekday != 'blank' ?
                            <TableCell sx={{fontSize: 22, fontWeight: 'bold'}}>{hours}</TableCell>
                            :
                            null
                        }

                    </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {
                        weekday != 'blank' ?
                        <TableCell >{block_name}</TableCell>
                        :
                        null
                    }
                </TableRow>
            </TableBody>
        </Table>
    )
}
function subtractTime(time1,time2) {
    let result1 = time1.split(":")
    let result2 = time2.split(":")
    
    if(+result2[0] > 0)
    {
     result1[0] = +result1[0]- (+result2[0])
    }
    result1[1] = +result1[1]- (+result2[1])
    if(+result1[1]< 0)
    {
    result1[0] = +result1[0] - 1
    result1[1] = +result1[1] + 60
    }
    return result1
}

function NetTimePay(data) {
    console.log("DATA", data)
    let obj = {
        time : '',
        wage : data?.rate,
        money : ''
    }
    let d_time
    let t_hours
    let hours
    if(data.drive_time != false)
    {
        if(data?.drive_time?.split(":")[1].length == 1)
        {
            d_time = data?.drive_time?.split(":")[0]+":0"+data?.drive_time?.split(":")[1]
        }
        else
        {
            d_time = data.drive_time
        }
    }
    else
    {
        d_time = "00:00"

    }

    if(data.total_hours.split(":")[1].length == 1)
    {
        t_hours = data.total_hours.split(":")[0]+":0"+data.total_hours.split(":")[1]
    }
    else
    {
        t_hours = data.total_hours
    }
    console.log("NET TIME HERE >> ",subtractTime(d_time,t_hours))
    let new_time = subtractTime(d_time,t_hours)
    if(new_time[0].toString().length == 1)
    {
        hours = '0'+new_time[0]
    }
    else{hours = new_time[0]}
    if(new_time[1].toString().length == 1)
    {
        hours = hours+':'+'0'+new_time[1]
    }
    else{hours = hours+':'+new_time[1]}
    obj.time = hours
    obj.money = ((+hours[0] * data.rate) + (+hours[1] * (data.rate/60))).toFixed(2)
    console.log(obj.money)

    return obj


    
}

const DayTotals_ListFormat = (weekday,date,totals,data) => {
    console.log("THE DATE",date)
    var block_name = ''
    var hours = ''
    var dt = false
    let display_first_shift = false
   if(date)
    {var found = totals.find(elem => elem.day == date)
    if(found)
    {//here to access day_summary
        block_name = found.week_day
        let total_hours = +found.regular_time.split(":")[0] + (+found.over_time.split(":")[0])
        let total_minutes = +found.regular_time.split(":")[1] + (+found.over_time.split(":")[1])
        while(total_minutes >= 60)
        {
            total_minutes = total_minutes - 60
            total_hours = total_hours + 1
        }
        hours = (total_hours.toString().length == 1 ? "0"+total_hours : total_hours)+":"+(total_minutes.toString().length == 1 ? "0"+total_minutes : total_minutes)

        dt = (found.start_end?.drive_time?.split(":")[0].length == 1 ? "0"+found.start_end?.drive_time?.split(":")[0] : found.start_end?.drive_time?.split(":")[0])+":"+(found.start_end?.drive_time?.split(":")[1].length == 1 ? "0"+found.start_end?.drive_time?.split(":")[1] : found.start_end?.drive_time?.split(":")[1])
    }
    else{
        if(data?.first_shift?.date == date)
        {
            display_first_shift = true
        }
        block_name = moment(date).format('dddd')
        hours = '00:00'
    }
    }

    if(weekday == 'Total')
    {//data is object batch
        block_name = "Total Drive Time:"
        if(data.total_hours.split(":")[1].length == 1)
        {
            const pt2 = "0"+data.total_hours.split(":")[1]
            if(data.total_hours.split(":")[0].length == 1)
            {
                const pt1 = "0"+data.total_hours.split(":")[0]
                hours = pt1+":"+pt2
            }
            else
            {
                hours = data.total_hours.split(":")[0]+":"+pt2

            }
        }
        else
        {
            if(data.total_hours.split(":")[0].length == 1)
            {
                const pt1 = "0"+data.total_hours.split(":")[0]
                hours = pt1+":"+data.total_hours.split(":")[1]
            }
            else
            {
                hours = data.total_hours

            }
        }

    }
    if(weekday == 'OverTime')
    {
        block_name = "Overtime:"

        hours = (data.ot_hours.toString().length == 1 ? "0"+data.ot_hours : data.ot_hours)+":"+(data.ot_minutes.toString().length == 1 ? "0"+data.ot_minutes : data.ot_minutes)
    }
    if(weekday == 'drive_time')
    {
        block_name = "Total Time Clock:"
        if(data.drive_time != false)
        {
            if(data.drive_time?.split(":")[1].length == 1)
            {
                hours = data?.drive_time?.split(":")[0]+":0"+data?.drive_time?.split(":")[1]
            }
            else
            {
                hours = data.drive_time
            }
        }
        else
        {
            hours = "00:00"

        }

    }
    if(weekday == 'net')
    {
        block_name = "Net Worked Hours:"
        let d_time
        let t_hours
        if(data.drive_time != false)
        {
            if(data?.drive_time?.split(":")[1].length == 1)
            {
                d_time = data?.drive_time?.split(":")[0]+":0"+data?.drive_time?.split(":")[1]
            }
            else
            {
                d_time = data.drive_time
            }
        }
        else
        {
            d_time = "00:00"

        }

        if(data.total_hours.split(":")[1].length == 1)
        {
            t_hours = data.total_hours.split(":")[0]+":0"+data.total_hours.split(":")[1]
        }
        else
        {
            t_hours = data.total_hours
        }
        console.log("NET TIME HERE >> ",subtractTime(d_time,t_hours))
        let new_time = subtractTime(d_time,t_hours)
        if(new_time[0].toString().length == 1)
        {
            hours = '0'+new_time[0]
        }
        else{hours = new_time[0]}
        if(new_time[1].toString().length == 1)
        {
            hours = hours+':'+'0'+new_time[1]
        }
        else{hours = hours+':'+new_time[1]}


    }

    return(

                <ErrorBoundary>
                    {//wekk day name
                        weekday != 'blank' && weekday != 'Total' && weekday != 'drive_time' && weekday != 'OverTime' && weekday != 'net' ?
                        <TableCell  align='left' width={'120px'} sx={{ paddingBottom:1, padding:0, margin:0,paddingLeft:1,fontSize: 15,fontWeight: 'bold',borderBottom: "none", color: 'black'}}>{block_name}</TableCell>
                        :
                        null
                    }
                    {weekday != 'blank' && weekday != 'Total' && weekday != 'drive_time' && weekday != 'OverTime' && weekday != 'net' ?
                    //clok in and out
                        found ?
                        <TableCell align='left' width={'100px'} sx={{ fontSize: 13, padding:0, paddingLeft: 1,paddingRight: 1,borderBottom: "none", color: 'black'}}>{found.start_end.start}-{found.start_end.end}</TableCell>
                        :
                        <TableCell align='left'  width={'100px'} sx={{ fontSize:13, padding:0, paddingLeft: 1,paddingRight: 1,borderBottom: "none", color: 'black'}}>{display_first_shift ? data?.first_shift.time : "00:00"}--00:00</TableCell>
                        :
                        null
                    }
                    {weekday != 'blank' && weekday != 'Total' && weekday != 'drive_time' && weekday != 'OverTime' && weekday != 'net' ?
                        //drive time
                        found ?
                        <TableCell align='left'  width={'40px'} sx={{ fontSize: 13, padding:0, paddingRight: 2,borderBottom: "none", color: 'black'}}>{dt ? dt: "null"}</TableCell>
                        :
                        <TableCell align='left'  width={'40px'} sx={{ fontSize: 13, padding:0, paddingRight: 2,borderBottom: "none", color: 'black'}}>00:00</TableCell>
                        :
                        null
                    }

                    {//comp time
                        weekday != 'blank' && weekday != 'Total' && weekday != 'drive_time' && weekday != 'OverTime' && weekday != 'net'?
                        <TableCell align='right'   width={'40px'} sx={{ fontSize: 12, padding:0, paddingRight: 2,borderBottom: "none", color: 'black'}}>{hours}</TableCell>
                        :
                        null
                    }






                    {//totals box name
                        weekday == 'Total' || weekday == 'drive_time'?
                        <TableCell width={'160px'} height={'20px'} align='right' sx={{ paddingBottom:1, padding:0, margin:0,paddingRight:2,fontSize: 15,fontWeight: 'bold',borderBottom: "none", color: 'black'}}>{block_name}</TableCell>
                        :
                        null
                    }

                    {//net box name
                        weekday == 'net' ?
                        <TableCell width={'170px'} height={'20px'} align='left' sx={{ padding:0,margin:0,paddingLeft:0.8,marginRight:0.1,fontSize: 15,fontWeight: 'bold',borderBottom: "none", color: 'black'}}>{block_name}</TableCell>
                        :
                        null
                    }

                    {//OverTime box name
                        weekday == 'OverTime' ?
                        <TableCell width={'160px'}  height={'20px'} align='right' sx={{ paddingBottom:1, padding:0, margin:0,paddingRight:2,fontSize: 15,fontWeight: 'bold',borderBottom: "none", color: 'black'}}>{block_name}</TableCell>
                        :
                        null
                    }

                    {//totals box values
                        weekday == 'Total' || weekday == 'drive_time' || weekday == 'OverTime' ?
                        <TableCell align='right' height={'20px'} sx={{ fontSize: 15, padding:0, paddingRight: 1,borderBottom: "none", color: 'black'}}>{hours}</TableCell>
                        :
                        null
                    }
                    {//totals box values
                        weekday == 'net' ?
                        <TableCell align='left' height={'20px'} sx={{ fontSize: 15, padding:0, paddingRight: 1,marginRight:0.2,borderBottom: "none", color: 'black'}}>{hours}</TableCell>
                        :
                        null
                    }
              </ErrorBoundary>
    )
}

const TimeTable = ({data, extra_data}) => {//use extra_data.days to get first and last day of pay period
	const state = store.getState()
    const companyLogo = extra_data.complogo
	var happend = false
	return(
		<div className="page-break-before flex flex-col items-center justify-center earning-print" >
            <ErrorBoundary>
                <div className='flex flex-col'>
                    <div className='flex flex-row items-end justify-end'>
                        {
                                companyLogo ?
                                <img width='200px' src={companyLogo}/>
                                :
                                null
                        }
                    </div>

                    <div className='flex flex-row'>
                        <div className='flex flex-col' style={{width: '342px'}}>
                                <Typography fontSize={24} fontWeight={'bold'} color={'black'}>{extra_data.name}</Typography>
                                <Typography fontSize={18}  color={'black'}>{extra_data.driver_address}</Typography>
                        </div>

                        <div className='flex flex-row items-end justify-end' style={{width: '342px'}}>
                                <Typography fontSize={18} fontWeight={'bold'} >Total Earnings:</Typography>&nbsp;
                                <Typography fontSize={18}>${editNums((+extra_data.additions_total + (+extra_data.commission_total) - (+extra_data.deductions_total)).toLocaleString())}</Typography>
                        </div>
                    </div>
                </div>
                <Box className="flex flex-col items-center mt-5" sx={{border: 2}}>
                    <AppBar className="flex flex-row items-center justify-center" position="static" elevation={0} layout="horizontal" sx={{height : "100%",width : "100%", backgroundColor: "#2c2e35", borderBottom: 2}}>
                        <div className='pr-160'>
                                <Typography variant="h5" gutterBottom color="white" fontWeight="bold">
                                        Driver Compensation
                                </Typography>
                        </div>
                        <div className='pr-64'>
                                <Typography variant="h5" gutterBottom color="white" fontWeight="bold">
                                        Time Log
                                </Typography>
                        </div>
                    </AppBar>

                    <div className='flex flex-row items-center justify-center'>
                        <Box height={321} width={341} sx={{borderRight: 1}}>
                            <TableContainer sx={{paddingTop: 1, overflow: 'hidden'}}>
                                    <Table sx={{[`& .${tableCellClasses.root}`]: {padding: 0, margin: 0}, }}>
                                        <TableHead>
                                            <TableRow><TableCell align='left'  style={{paddingLeft: 45, fontSize: 15, borderBottom: "none", fontWeight: 'bold' }}>Pay Period:</TableCell>
                                                    { extra_data?.days &&
                                                    <TableCell colSpan={2} style={{paddingRight: 0, borderBottom: "none", marginLeft: 50}}>{moment(extra_data?.days[0]).format("MM/DD")} - {moment(extra_data?.days[6]).format("MM/DD, YYYY")}
                                                    </TableCell> }
                                            </TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Hourly Rate:</TableCell><TableCell align='left' style={{borderBottom: "none", paddingRight: 50}}>${extra_data.rate}</TableCell></TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Drive Time:</TableCell> <span><TableCell align='left' width={'5%'} style={{borderBottom: "none"}}>${(Math.round(+(editNums(((+extra_data.reg_hours * +extra_data.rate) + (+extra_data.reg_minutes * (+extra_data.rate/60))).toString()))* 100) / 100).toFixed(2)}</TableCell><TableCell align='left' style={{borderBottom: "none", fontSize: 13, paddingLeft: 2}}>{`( ${extra_data.rate}*${extra_data.reg_hours}:${extra_data.reg_minutes.toString().length == 1 ? "0"+extra_data.reg_minutes : extra_data.reg_minutes})`}</TableCell></span></TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Net Worked Hours:</TableCell> <span><TableCell align='left' width={'5%'} style={{borderBottom: "none"}}>${(extra_data?.net_money.toLocaleString())}</TableCell><TableCell align='left' style={{borderBottom: "none", fontSize: 13, paddingLeft: 2}}>{`(${NetTimePay(extra_data).time})`}</TableCell></span></TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Overtime:</TableCell><TableCell align='left' style={{borderBottom: "none", paddingRight: 50}}>${editNums((+extra_data.ot_pay).toLocaleString())}</TableCell></TableRow>
                                            



                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Total Wages:</TableCell> <span><TableCell align='left' width={'5%'} style={{borderBottom: "none"}}>${(+extra_data?.total_pay + +extra_data?.net_money).toLocaleString()}</TableCell><TableCell align='left' style={{borderBottom: "none", fontSize: 13, paddingLeft: 2}}>{`(${extra_data?.drive_time != false ? extra_data?.drive_time : "00:00"} hrs)`}</TableCell></span></TableRow>
                                            




                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Commission:</TableCell> <span><TableCell align='left'  width={'5%'} style={{borderBottom: "none"}}>${Number(editNums((extra_data.commission_total-extra_data.total_pay).toString())) < 0 ? "0.00" : editNums((extra_data.commission_total-extra_data.total_pay).toLocaleString()) }</TableCell> <TableCell align='left' style={{borderBottom: "none", fontSize: 13, paddingLeft: 2}}>{`(${(extra_data.commission_percent)})`}</TableCell></span></TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,fontWeight: 'bold'}}>Sub Total:</TableCell> <TableCell align='left' style={{paddingRight: 50}}>${editNums((+extra_data.commission_total).toLocaleString())}</TableCell> <TableCell style={{borderBottom: 'none'}}></TableCell></TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Additional Pay:</TableCell> <TableCell align='left' style={{borderBottom: "none", paddingRight: 50}}>${editNums((+extra_data.additions_total).toLocaleString())}</TableCell> </TableRow>
                                            <TableRow><TableCell align='left' style={{paddingLeft: 45, fontSize: 15,borderBottom: "none", fontWeight: 'bold'}}>Deductions:</TableCell> <TableCell align='left' style={{borderBottom: "none", paddingRight: 50}}>(${editNums((+extra_data.deductions_total).toLocaleString())})</TableCell> </TableRow>
                                        </TableHead>
                                    </Table>
                            </TableContainer>
                            <Box border={0} borderTop={2} height={"340px"} marginTop={"4px"} paddingTop={"7px"}>
                                <Typography paddingLeft={7.5} fontSize={17} fontWeight="bold">
                                        Total Earnings This Pay
                                </Typography>
                                <span style={{display: "flex"}}>
                                        <Typography paddingLeft={7.5} fontSize={17} fontWeight="bold">
                                                Period:
                                        </Typography>
                                        &nbsp;
                                        <Typography fontSize={17} >
                                        ${editNums((+extra_data.additions_total + (+extra_data.commission_total) - (+extra_data.deductions_total)).toLocaleString())}
                                        </Typography>
                                </span>
                            </Box>
                        </Box>

                        <Box  height={320} width={341} sx={{borderLeft: 1, paddingTop: 1}}>
                            <Table>
                                <TableHead >
                                    <TableRow sx={{padding : 0}}>
                                            <TableCell width={'15%'} sx={{borderBottom: 0, padding:0}}></TableCell>
                                            <TableCell width={'130px'} sx={{padding: 0, border: 0,borderBottom:1,paddingRight:0}}>Clock in - Clock out</TableCell>
                                            <TableCell width={'10px'} sx={{padding: 0, border: 0, borderBottom:1,paddingLeft:'0px'}}>Time Clock</TableCell>
                                            <TableCell width={'10px'} sx={{padding: 0, border: 0, borderBottom:1,paddingLeft:'0px'}}>Drive Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{padding : 0}}> {extra_data?.days ? DayTotals_ListFormat('Monday', extra_data.days[0],extra_data.day_summary,extra_data): ''}</TableRow>
                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Tuesday',extra_data.days[1],extra_data.day_summary,extra_data) : ''}</TableRow>
                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Wednesday',extra_data.days[2],extra_data.day_summary,extra_data): ''}</TableRow>

                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Thursday',extra_data.days[3],extra_data.day_summary,extra_data): ''}</TableRow>
                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Friday',extra_data.days[4],extra_data.day_summary,extra_data): ''}</TableRow>
                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Saturday',extra_data.days[5],extra_data.day_summary,extra_data): ''}</TableRow>


                                    <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Sunday',extra_data.days[6],extra_data.day_summary,extra_data): ''}</TableRow>
                                    {
                                            extra_data?.days && extra_data?.days[7] ?
                                            <TableRow sx={{padding : 0}}>{ extra_data?.days ? DayTotals_ListFormat('Monday',extra_data.days[7],extra_data.day_summary,extra_data) : ''}</TableRow>
                                            :
                                            <TableRow sx={classes.blank}>{ DayTotals_ListFormat('blank')}</TableRow>
                                    }
                                </TableBody>
                            </Table>
                            <Box border={0} borderTop={2} height={"340px"} paddingTop={"5px"} marginTop={"32px"} >
                                <Grid container item xs={12} >
                                        <Grid item xs={12}><Paper  sx={classes.paper}>{DayTotals_ListFormat('drive_time','',[], extra_data)}</Paper></Grid>
                                        <Grid item xs={12}><Paper  sx={classes.paper}>{DayTotals_ListFormat('Total','',[], extra_data)}</Paper></Grid>
                                        <Grid item xs={12}><Paper  sx={classes.paper}>{DayTotals_ListFormat('net','',[], extra_data)}</Paper></Grid>
                                        <Grid item xs={12}><Paper  sx={classes.paper}>{DayTotals_ListFormat('OverTime','',[], extra_data)}</Paper></Grid>
                                </Grid>
                            </Box>

                        </Box>

                    </div>
                </Box>
            </ErrorBoundary>
		</div>
	)
}

const CombineTables = ({time_data, data, extra_data,index}) => {
	return(
    <div key={index}>
        <TimeTable data={time_data} extra_data={extra_data}/>
        <JobTable data={data}/>
        <AdditionsTable data={extra_data}/>
        <DeductionsTable data={extra_data}/>
    </div>
	)
}



const UserTable = () => {
    //the div was neccessary to create all the tables for both users
	return (
		<div>
			<div className="Report">
				{
					ReportData.length > 0 ?
					ReportData.map((elem,index) => {
							return(
                                <ErrorBoundary>
									<CombineTables index={index} extra_data={elem} time_data={elem.time_data} data={elem.data}/>
                                </ErrorBoundary>
							)
					})
					: null
				}
			</div>
			<div className='footer'><Typography fontSize={15} sx={{marginTop: 1, marginRight: 1}}>Powered by</Typography><img style={{width: 80, height: 20, marginTop: 7}} src='assets/images/logos/loadzlogo.png'/></div>
		</div>

	)
}

export default class EarningsReport extends React.Component {
	constructor(props) {
			super(props);
	}

	render() {
		ReportData = []
		if(this.props) {
			if(this.props?.id) {
					var timesheets = this.props.timesheets?.find(elem => elem.id == this.props?.id)
					timesheets = [timesheets]
			} else{
				var timesheets = this.props.timesheets
			}

			for(const earnings_user of this.props.earnings) {
					const idx = this.props.earnings.indexOf(earnings_user)
					createData(earnings_user,timesheets[0],this.props.batch_access)
			}
			return (
				<UserTable />
			)
		}
	}
}
