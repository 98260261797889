import React, {useState,useRef, useEffect} from 'react';
import { Dialog, DialogActions, DialogContent, Typography, DialogTitle, Button, Card, Tooltip, TextField } from '@mui/material';
import { constants } from '../../../../constants';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid'
import { Icon, IconButton, MenuItem, Select,Box } from '@mui/material';
import { has } from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import useOpenImagePreview from 'app/hooks/useOpenImagePreview';



const ViewCustomerBolImages = ({
    open,
    closePreview,
    images,

}) => {

    const {handlePreview} = useOpenImagePreview()


    const dispatch = useDispatch();

    const handleClose= () => {
        closePreview(false);
    }


 
    return(
        <Dialog
            open={open}
            onClose={(e) => {e.stopPropagation(); handleClose()}}
            maxWidth="lg"
            fullWidth
        >

            <DialogContent sx={{ display: 'grid', gridTemplateColumns: images?.length >= 3 ? '1fr 1fr 1fr' : images?.length == 2 ? '1fr 1fr' : '1fr', gap: 4, rowGap:8 ,justifyItems: 'center', alignItems: 'center', height: images?.length + 1 > 3 ? '80vh' : '60vh',marginTop:'32px',marginBottom:'32px'}}>
                {
                    images?.map((image,index) => {
                        return(
                            <Box 
                                sx={{ border: 1, borderRadius:'5px 5px 0px 0px' ,maxHeight:'250px', height: '100%', width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <img
                                    src={`${constants.URLLOCAL}/aws/signed?image=${image.key}`}
                                    alt="BOL Image"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        flexGrow: 1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        handlePreview(image.key)

                                    }}
                                    
                                />

                                <Box className='flex justify-evenly align-center' sx={{borderRadius:'0px 0px 5px 5px', width:'200px',border:1, backgroundColor:'#edca33' }}>
                                
                                    <IconButton
                                        onClick={() => {
                                            handlePreview(image.key)
                                        }}
                                        sx={{
                                        color: 'white',
                                        backgroundColor: 'secondary.main',
                                        '&:hover': {
                                            backgroundColor: 'secondary.dark',
                                        },
                                        boxShadow: 3,
                                        }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>

            
                                </Box>
                                
                            </Box>
                        )
                    })
                }

            </DialogContent>
            
        </Dialog>
    )
}

export default ViewCustomerBolImages;