import React, {useState,useRef, useEffect} from 'react';
import Annotation from 'react-image-annotation';
import { Dialog, DialogActions, DialogContent, Typography, DialogTitle, Button, Card, Tooltip, TextField } from '@mui/material';
import { constants } from '../../../../constants';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid'
import { Icon, IconButton, MenuItem, Select } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import './ImageAnnotationsEffects.css';
import { has } from 'lodash';
import ImageAnnotationTextEdit from './ImageAnnotationTextEdit';

const colorPicker = ['#f54242', '#00ff00', '#0000ff', '#ffff00']

const ImageAnnotation = ({open,handleClose,saveAnnotations,imageData=null,createButtonLog}) => {


    const dispatch = useDispatch();
    const [annotations, setAnnotations] = useState( []);
    const [annotation, setAnnotation] = useState({});
    const [selectedImage, setSelectedImage] = useState( null);
    const undoStackRef = useRef([]);
    const redoStackRef = useRef([]);
    const [fileLoading, setFileLoading] = useState(false)
    const [fileUrl, setFileUrl] = useState(null)

    const handleClosing = () => {
      setSelectedImage(null);
      setAnnotations([]);
      setAnnotation({});
      undoStackRef.current = [];
      redoStackRef.current = [];
      handleClose();
    }

    useEffect(() => 
      {
        if(open)
        {
          setSelectedImage(imageData)
          setAnnotations(imageData?.image?.annotations && imageData?.image?.annotations.length ? imageData?.image.annotations : []);
          undoStackRef.current = [];
          redoStackRef.current = [];
        }

      }, [open]);

    const _showMessage = (msg, color) => {
        dispatch(
          showMessage({
            message: (
              <>
                <Typography>{msg}</Typography>
              </>
            ), //text or html
            autoHideDuration: 6000, //ms
    
            anchorOrigin: {
              vertical: 'top', //top bottom
              horizontal: 'right', //left center right
            },
            variant: color, //success error info warning null
          })
        );
    };
  
    const style = {
      button: "text-[#fff] bg-[#4ca3dd] py-[2px] px-2 rounded-[5px]",
    }
  
    const StyledBox = ({ children, geometry, style }) => (
      <div
        style={{
          ...style,
          position: 'absolute',
          left: `${geometry.x}%`,
          top: `${geometry.y}%`,
          height: `${geometry.height}%`,
          width: `${geometry.width}%`,
        }}
      >
        {children}
      </div>
    )

    //selector box
    function renderSelector({ annotation }) {
      const { geometry } = annotation;
      if (!geometry) return null
  
      return (
        <StyledBox
          geometry={geometry}
          style={{
            border: `solid 2px #E10000`
          }}
        />
      )
    }
    
    //annotation box
    function renderHighlight({ annotation }) {
      const { geometry} = annotation;
      const {visibility = null} = annotation.data;
      const idIntegerPart = Math.floor(geometry?.height);
  
      if (!geometry) return null

      if(visibility == null || visibility == undefined || visibility == true)
      {
        return (
          <StyledBox
            key={annotation.data.annote_id}
            geometry={geometry}
            
            style={{
              border: `solid 3px ${annotation.data?.color ? annotation.data.color : '#0024E1'}`,
            }}
          />
        )
      }
    }
  
    // annotation text
    function renderContent({ annotation }) {
      const { geometry } = annotation;
      const {visibility = null} = annotation.data;
      const idIntegerPart = Math.floor(geometry?.height);

      if(visibility == null || visibility == undefined || visibility == true)
      {
        return (
          <div
            id={annotation.data.annote_id}
            key={annotation.data.annote_id}
            style={{
              background: `${annotation.data?.color ? annotation.data.color : '#0024E1'}`,
              color: 'black',
              paddingRight: 10,
              paddingLeft: 10,
              fontWeight: "bolder",
              fontSize: 15,
              position: 'absolute',
              left: `${geometry.x}%`,
              top: `${geometry.y + geometry.height}%`,
              maxWidth: '320px',
              wordWrap: 'break-word', // Ensure text wraps within the width
              whiteSpace: 'normal', // Allow text to wrap
            }}
          >
            {annotation.data && annotation.data.text}
            
            <Button 
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(annotation.data.annote_id);
            }}
            >
              <Icon>cancel</Icon>
            </Button>
          </div>
        )
      }
      
    }
  
    //editor for annotation
    function renderEditor (props) {
      const { geometry } = props.annotation
      if (!geometry) return null
    
      return (
        <div
          style={{
            background: 'white',
            borderRadius: 3,
            position: 'absolute',
            left: `${geometry.x}%`,
            top: `${geometry.y + geometry.height}%`,
          }}
          className=" flex p-2 rounded-[10px] mt-[5px]"
        >
         <div className='flex flex-col items-center w-full'>
          <input
            onChange={e => props.onChange({
              ...props.annotation,
              data: {
                ...props.annotation.data,
                text: e.target.value
              }
            })}
            placeholder="write a description"
            className="block mt-1 p-2 focus:outline-none"
          />
          <Button variant='contained' onClick={props.onSubmit} style={{backgroundColor:'#edca33'}} className={`flex w-full m-2`}>Comment</Button>
         </div>

          <div className="flex flex-col items-center justify-evenly w-full ml-8">
            <Select
                value={props.annotation.data?.color || ''}
                onChange={(e) =>
                    props.onChange({
                    ...props.annotation,
                    data: {
                        ...props.annotation.data,
                        color: e.target.value,
                        },
                        })
                    }
                displayEmpty
                style={{padding:0}}
            >

                    <MenuItem value="" disabled>
                        Select a color
                    </MenuItem>
                    {colorPicker.map((color, index) => {
                        return(
                            <MenuItem key={index} value={color}>
                                <div
                                className='flex flex-col'
                                style={{
                                    backgroundColor: color,
                                    width: '100%',
                                    height: 20,
                                    marginRight: 8,
                                }}
                                ></div>
                            </MenuItem>
                        )
                    })
                    }
            </Select>
          </div>

        </div>
      )
    }
  
    //current annotation
    const onChange = (newAnnotation) => {
      //console.log('onChange: ',newAnnotation);
      setAnnotation(newAnnotation);
    };
  
    const onSubmit = async (newAnnotation) => {

      createButtonLog('create')

      console.log('onSubmit: ',newAnnotation);
      console.log(imageData)
      console.log(selectedImage)
      const { geometry, data } = newAnnotation;
  
      //empty redo 
      redoStackRef.current = [];
  
      //clears current annotation
      setAnnotation({});

      //creates annotated image
      const newAnnotationData = {
        geometry,
        data: {
          ...data,
          id: selectedImage?.image.id,
          annote_id: uuid()
        },
      };
      
      //saves image
      setAnnotations([...annotations, newAnnotationData]);
      console.log('onSubmit: ',[...annotations, newAnnotationData]);
      saveAnnotations([...annotations, newAnnotationData]);
  

      //adds to the undo
      undoStackRef.current.push(annotations);

      _showMessage('Annotations added', 'success');
    };
  
  
    const handleUndo = () => {
        /**
         * pop the last state array and make it the current one
         * make the current one the redo state
         * 
         * using pop and push to avoid mutating the entire state
         */
        if (undoStackRef.current.length > 0) {
          createButtonLog('undo')
          const lastAnnotations = undoStackRef.current.pop();
          redoStackRef.current.push([...annotations]);
          setAnnotations(lastAnnotations);
          saveAnnotations([...lastAnnotations]);
        }
    };
  
    const handleRedo = () => {
      if (redoStackRef.current.length > 0) {
        createButtonLog('redo')
        const nextAnnotations = redoStackRef.current.pop();
        undoStackRef.current.push([...annotations]);
        setAnnotations(nextAnnotations);
        saveAnnotations([...nextAnnotations]);
      }
    };

    const handleDelete = (annote_id) => {
      createButtonLog('delete')
      undoStackRef.current.push([...annotations]);
      const newAnnotations = annotations.filter((anno) => anno.data.annote_id !== annote_id);
      setAnnotations(newAnnotations);
      saveAnnotations(newAnnotations);
    }
  
    //apply previously created annotations to the new image
    const applyAnnotationsToNewImage = (previousAnnotations) => {
      return previousAnnotations
        .map(anno => ({
          geometry: anno.geometry,
          data: {
            ...anno.data,
            id: selectedImage.image.id,
            annote_id: uuid()
          }
        }));
    };
    
    const applyAnnotationsToSelectedImage = () => {
      const annotationsForNewImage = applyAnnotationsToNewImage(annotations);
      setAnnotations([...annotations, ...annotationsForNewImage]);
    };

    const setField = (field,idx) => {
      createButtonLog('field')
      const newAnnotations = [...annotations];
      newAnnotations[idx].data.field = field;
      setAnnotations(newAnnotations);
      saveAnnotations(newAnnotations);
    }

    const handleScrollToAnnotation = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        element.classList.add('glow');
        setTimeout(() => {
          element.classList.remove('glow');
        }, 1000); // Remove the glow effect after 1 second
      }
    };

    const handleAnnotationVisibility = (id,idx) => {
      const newAnnotations = [...annotations];
      if(has(newAnnotations[idx].data,'visibility'))
      {
        newAnnotations[idx].data.visibility = !newAnnotations[idx].data.visibility;
      }
      else
      {
        newAnnotations[idx].data.visibility = false;
      }
      setAnnotations(newAnnotations);
      saveAnnotations(newAnnotations);
    }

    const [startEdit,setStartEdit] = useState(false);

    const handleSaveEdit = (annote_id,idx,text) => {
      createButtonLog('edit_text')
      const newAnnotations = [...annotations];
      newAnnotations[idx].data.text = text;
      setAnnotations(newAnnotations);
      saveAnnotations(newAnnotations);
    }

    const getUrl = async () => {
      try {
        
        if (!imageData?.image?.key.includes('pdf')) {
          setFileUrl(`${constants.URLLOCAL}/aws/signed?image=${imageData?.image.key}`)
          return
        } 

        const params = { image: imageData?.image.key, url: true, contentType: 'pdf' };
        setFileLoading(true);
        const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
          params,
        });
  
        const url = response?.data;
        if (url) {
          setFileUrl(url);
        }
      } catch (error) {
        console.error('Error fetching signed URL:', error);
      } finally {
        setFileLoading(false); // Stop loading when done
      }
    };
  
    useEffect(() => {
      if (imageData?.image.key) {
        getUrl()
      }
    }, [imageData?.image?.key])

    return(
        <Dialog
            open={open}
            onClose={(e) => {e.stopPropagation(); handleClosing(); setFileUrl(null)}}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle className='flex flex-col justify-center items-center align-center' sx={{borderBottom:2,backgroundColor:'#edca33'}} >
                <Typography variant='h5'>Image Annotation</Typography>
                {/**redo undo buttons */}
                <div className="flex gap-4 justify-center items-center my-4">
                    <Button variant='contained' style={{backgroundColor:'black',color:'white'}} onClick={handleUndo} >Undo</Button>
                    <Button variant='contained' style={{backgroundColor:'black',color:'white'}}  onClick={handleRedo} >Redo</Button>
                    {/*  <button className={style.button} onClick={applyAnnotationsToSelectedImage} type="button">Apply all</button> */}
                </div>
            </DialogTitle>

            <DialogContent className='flex-col'>
              <div className='flex flex-row justify-center items-center mb-12 mt-12'>
                <Typography fontWeight={'bold'} fontStyle={'italic'}>Click and drag to place a box where the annotations will be placed.</Typography>
              </div>

              <div className='flex'>
                <div className="mt-6">
                    {/**image annotation */}
                    
                    <div className="w-full md:w-[400px] m-auto cursor-crosshair">
                        <Annotation
                        src={fileUrl}
                        alt="Annotate image"
                        annotations={annotations.filter((anno) => anno.data.id === selectedImage.image.id)}
                        activeAnnotations={annotations.filter((anno) => anno.data.id === selectedImage.image.id)}
                        value={annotation}
                        type={annotation.type}
                        className="h-[300px]"
                        onChange={onChange}
                        onSubmit={onSubmit}
                        allowTouch
                        renderOverlay={() => null}
                        renderSelector={renderSelector}
                        renderHighlight={renderHighlight}
                        renderContent={renderContent}
                        renderEditor={renderEditor}
                        />
                    </div>

                    
                </div>

                <div 
                  className='flex flex-col w-2/5 ml-16 gap-y-4' 
                  style={{
                    width:'100%',
                    maxWidth: '350px',
                  }}
                >
                   {
                      annotations.filter((anno) => anno.data.id === selectedImage.image.id).map((annotation,idx) => {
                        return(
                          <Card 
                            key={annotation.data.annote_id} 
                            className='flex flex-col mt-4'
                          >
                              <div 
                              style={{
                                backgroundColor: annotation.data?.color ? annotation.data.color : '#0024E1',
                                padding: '8px',
                                width: '100%',
                              }} 
                              className='flex flex-row justify-between w-full gap-y-6'
                            >
                              <Tooltip title='Select the type of annotation' placement='top'>
                                <Select
                                  variant='standard'
                                  sx={{
                                    padding:0,
                                    width: '50%',
                                  }}
                                  label='Field'
                                  value={annotation.data?.field || ' '}
                                  onChange={(e) => {
                                    setField(e.target.value,idx);
                                  }}
                                >
                                  <MenuItem value=' '>Empty Field</MenuItem>
                                  <MenuItem value='tk_qty'>Ticket Qty</MenuItem>
                                  <MenuItem value='tk_scale_no'>Ticket Scale #</MenuItem>
                                  <MenuItem value='truck_id'>Ticket Truck #</MenuItem>
                                  <MenuItem value='signature'>Signature</MenuItem>

                                </Select>
                              </Tooltip>
                              
                              <Tooltip title='go to' placement='bottom'>
                                <IconButton
                                  onClick={() => handleScrollToAnnotation(annotation.data.annote_id)}

                                >
                                  <ReplyIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='visibility' placement='right'>
                                <IconButton
                                  onClick={() => handleAnnotationVisibility(annotation.data.annote_id,idx)}
                                >
                                    <Icon>
                                      {has(annotation.data,'visibility') ? annotation.data.visibility ?  "visibility" : "visibility_off" : "visibility"}
                                    </Icon>
                                </IconButton>
                              </Tooltip>



                              </div>

                              <div 
                                style={{
                                  padding: '8px',
                                  width: '100%',
                                }}
                                className='flex flex-row justify-between align-center items-center'
                              >
                                <ImageAnnotationTextEdit handleSave={handleSaveEdit} annotation={annotation} idx={idx} />
                               
                              </div>
                          </Card>
                        )
                      })
                   }
                </div>
              </div>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' style={{backgroundColor:'black',color:'white'}} onClick={(e) => {e.stopPropagation(); handleClosing(); setFileUrl(null)}}>Finish</Button>
            </DialogActions>


        </Dialog>
    )
}

export default ImageAnnotation;