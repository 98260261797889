import { takeLatest, put, fork, all, call } from 'redux-saga/effects'
import actions from './actions'
import client from '../../../../../client'
import { getErrorAction, getSuccessAction } from '../utility'

export function* watchGetAllJobs() {
  yield takeLatest(actions.GET_JOBS, function* ({ payload, onError }) {
    console.log(payload)
    try {
      let uri = payload.data?.compacctnum ? `/jobs/get/${payload.data?.compacctnum}` : '/jobs/get/null'
      uri = payload?.data?.filter ? `${uri}/${payload.data.filter}` : uri

      console.log(`Jobs: loading from ${uri}`)
      const result = yield call(() =>
        client.get(uri, { params: payload.params })
      )
      console.log(result.response)
      yield put({
        type: getSuccessAction(actions.GET_JOBS),
        payload: {
          jobs: Array.isArray(result?.data) ? result.data : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      })
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.GET_JOBS),
      })
    }
  })
}

export function* watchSearchJobs() {
  yield takeLatest(actions.SEARCH_JOBS, function* ({ payload, onError }) {
    console.log(payload)
    try {
      const result = yield call(() => client.get(`/jobs${payload.data?.compacctnum ? `/${payload.data.compacctnum}/`:'/'}search/${payload.data.text}/${payload.search.search}${payload.data.type ? `/${payload.data.type}`:''}`, { params: payload.params }))
      yield put({
        type: getSuccessAction(actions.GET_JOBS),
        payload: {
          jobs: Array.isArray(result.data) ? result.data : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      })
    } catch (error) {
      if (onError) onError(error)

      yield put({
        type: getErrorAction(actions.GET_JOBS),
      })
    }
  })
}

export function* watchGetPendingJobs() {
  yield takeLatest(actions.GET_PENDING_JOBS, function* ({ payload, onError }) {
    console.log(payload)
    try {
      const result = yield call(() => client.get(`/jobs/pending-jobs/${payload.compacctnum}`))
      yield put({
        type: getSuccessAction(actions.GET_PENDING_JOBS),
        payload: {
          count: result.data.count,
        },
      })
    } catch (error) {
      if (onError) onError(error)

      yield put({
        type: getErrorAction(actions.GET_PENDING_JOBS),
      })
    }
  })
}

export function* watchGetBolPendingCount() {
  yield takeLatest(actions.GET_BOL_PENDING_COUNTER, function* ({ payload, onError }) {
    //console.log('watchGetBolPendingCount =>> ',payload)
    try {
      const result = yield call(() => client.get(`/jobs/bol-pending-count/${payload.data.compacctnum}`))
      console.log('watchGetBolPendingCount',result.data)
      yield put({
        type: getSuccessAction(actions.GET_BOL_PENDING_COUNTER),
        payload: {
          count: result.data.count,
        },
      })
    } catch (error) {
      if (onError) onError(error)

      yield put({
        type: getErrorAction(actions.GET_BOL_PENDING_COUNTER),
      })
    }
  })
}



export default function* rootSaga() {
  yield all([fork(watchGetAllJobs), fork(watchSearchJobs),fork(watchGetPendingJobs),fork(watchGetBolPendingCount)])
}
