import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { SocketContext } from './SocketContext';
import { constants } from '../constants';

export const SocketProvider = ({ children }) => {
    const socket = useRef(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const _io = io(constants.URLSOCKET, { transports: ["websocket"] });

        _io.on('connect', () => {
          setIsConnected(true);
          console.log('socket::: connected')
          _io.emit('authenticate', window.localStorage.getItem('token'))
          _io.emit('authenticate_bol', window.localStorage.getItem('token'))

        });

        _io.on('disconnect', () => {
          setIsConnected(false);
        });

        socket.current = _io

        return () => {
          if (socket.current) {
            socket.current.off('connect');
            socket.current.off('disconnect');
            socket.current.disconnect();
            console.log('socket::: disconnect')
          }
        };
    }, []);

    return (
      <SocketContext.Provider value={{ socket: socket.current, isConnected }}>
        {children}
      </SocketContext.Provider>
    );
};
